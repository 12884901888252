import React from 'react';

const HeaderButton = ({title, icon, click, active = false}) => {
    return (
        <button type='button' onClick={click} className={active ? 'mBtn active' : 'mBtn'}>
            <i className={icon}></i>&nbsp;<p>{title}</p>
        </button>
    );
}

export default HeaderButton;
