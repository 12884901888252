import React, { useEffect, useRef } from 'react';
import LoadingButton from '../../LoadingButton';

export default function TextField ({ input, meta, label, required, children, btnProps, prefixText, ...others }) {
  const style = ((meta.error || meta.submitError) && meta.touched && !others.readOnly) ? { 'color': 'red' } : {};
  const fieldRef = useRef(null);

  useEffect(()=>{
    fieldRef.current && fieldRef.current.setCustomValidity((meta.error && meta.touched) ? meta.error : '');
  });

  return (
    <div className={`form-group ${others.className ? others.className : ''}`} style={{ opacity: others.disabled ? 0.5 : 1}}>    
      <label htmlFor={others.id} style={style}>{label} {required && !others.readOnly && <span className="is-required">*</span>}</label>
      
      {
        prefixText && <span>{prefixText}</span>
      }

      {
        others.readOnly ? 
          <div>{input.value}</div>
        :
          <input ref={fieldRef} {...input} type={input.type || 'text'} {...others} className={`${btnProps ? 'has-button' : ''} ${prefixText ? 'has-prefix' : ''}`} 
            onChange={e => {
              others.onChange && others.onChange(e);
              input.onChange(e);
            }} 
          />
      }

      {
        btnProps && <LoadingButton {...btnProps} />
      }
      <i className="mtrl-select" />
      {children}
    </div>
  );
}