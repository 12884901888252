import React from 'react';
import { Dropdown } from 'react-bootstrap';

const HeaderButton = ({title, icon, click, active = false}) => {
    return (
        <Dropdown.Item onClick={click} active={active}><i className={icon}></i>&nbsp;{title}</Dropdown.Item>
    );
}

export default HeaderButton;
