import React, { useState } from 'react';
import Content from './content';
import Header from './header';

const Manager = () => {
    const [page, setPage] = useState(0);
    const [pageTitle, setPageTitle] = useState('About Us');

    return (
        <div className='managerContainer'>
            <Header setPage={setPage} page={page} setPageTitle={setPageTitle}/>
            <Content pageId={page} pageTitle={pageTitle}/>
        </div>
    );
}

export default Manager;
