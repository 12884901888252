import React, { Fragment } from 'react';
import {useTranslation} from "react-i18next";
import TopBar from '../../Components/TopBar';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Tooltip from '../../Components/Tooltip';

export default function Welcome({children}) {
  const {t} = useTranslation('common');

  const tooltip = (
    <div>
      MEMKEEPERS creates a virtual “grave” site where you keep the memory of your loved ones.<br/>
      1. First, create a user profile.<br/>
      2. Second, create an honoree profile and invite relatives & friends to form a group<br/>
      <ul>
        <li>An honoree can be either a person or a pet. These are on two separate tabs.</li>
        <li>Group members post photos, videos, share memories, or anything that is on their heart.</li>
        <li>Users can post graphical mementos - like flowers, pebbles, miniature statues, teddy bears, balloons, candles, and others.</li>
        <li>Group calendars are to schedule memorial events.</li>
      </ul>
      3. Businesses and celebrities can create profile pages to showcase their products or services.<br/>
      4. Business groups can create advertising posts.
    </div>
  );

  return(
    <Fragment>
      <TopBar/>
      <div className="theme-layout marble">
        <div className="welcome container">
          <div className="row merged">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="land-featurearea">
                <div className="land-meta">
                  {/* <Tooltip tooltip={tooltip}> */}
                    <Fragment>
                      <h1>{t('welcome.welcome')} <img src={logo} alt="logo" /></h1>
                      <p>{t('welcome.summary')}</p>
                      <ul>
                        <li>{t('welcome.b1')}</li>
                        <li>{t('welcome.b2')}</li>
                        <li>{t('welcome.b3')}</li>
                        <li>{t('welcome.b4')}</li>
                      </ul>
                    </Fragment>
                  {/* </Tooltip> */}
                  <center>  
                    <Link to="/aboutmemkeepers">{t('welcome.about-us')}</Link><br />
                    <Link to="/howitworks">{t('welcome.how-works')}</Link><br />
                  </center>
                </div>  
              </div> 
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="login-reg-bg" >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </Fragment>
  );
}