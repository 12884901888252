import React, { useRef, useEffect } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { saveTrackingData } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';
//import { request } from '../../Services/networkService';

export default function Groups(){
  const {t} = useTranslation('common');
  const { handleError } = usePopupContext();
  const { user } = useUserContext();
  const tableRef = useRef();
  const history = useHistory();

  const columns = [{
    Header: t('admin.name'),
    accessor: 'Name'
  }, {
    Header: t('admin.type'),
    accessor: '',
    Cell: ({value}) => value.Owner === value.RelatedTo ? t('admin.personal') : value.GroupType
  }];

  const getLink = group => {
    if (group.GroupType === 'Page') {
      return `/userpage/${group.RelatedTo}`;
    } else if (group.GroupType === 'Honoree') {
      return `/honoreetimeline/${group.RelatedTo}`;
    } else { 
    return `/usertimeline/${group.RelatedTo}`;
    }
  };

  const actions = [
  // {
  //   iconcls: 'fa fa-link',
  //   onClick: row => request('admin.groups.link', {userId: row.RelatedTo, groupId: row._id}),
  //   title: "Check user-group link"
  // }
  ];

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/groups')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-world" /> {t('admin.groups')}</h5>

        {/*<button onClick={() => request('admin.util')}>t('admin.utilBtn')</button>*/}

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.groups.list'}
          disableFilters
          onError={handleError}
          defaultSorted={[{
            id: 'Category',
            desc: true
          }]}
        />

      </div>
    </div>
  );
}
