import React, { useRef } from 'react';
import ReactTable from '../../../Components/ReactTablePagination';
import { formatDateTime } from '../../../Util/common';
import { usePopupContext } from '../../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import MainStatistics from './statistics';

const Dashboard = () => {
    const {t} = useTranslation('common');
    const { handleError } = usePopupContext();
    const tableRef = useRef();

    const formatDeviceCell = (device) => {
        if(device.isMobile || device.isTablet) {
            return device.vendor + ' ' + device.model;
        } else if(device.isBrowser) {
            return device.browserName + ', ' + device.osName
        } else {
            return '-----'
        }
    }

    const columns = [{
        Header: '',
        accessor: '_id',
        width: 27,
        Cell: () => <i className='ti-location-pin'></i>
    }, {
        Header: t('admin.email'),
        accessor: 'userEmail',
        width: 250
    },{
        Header: 'Usernames',
        accessor: 'userNames'
    },{
        Header: 'Page',
        accessor: 'page',
    },{
        Header: 'City & Country',
        accessor: 'geo',
        Cell: ({value}) => value.city + ', ' + value.country_name,
    },{
        Header: 'IP',
        accessor: 'geo.IPv4',
    },{
        Header: 'Device',
        accessor: 'device',
        Cell: ({value}) => formatDeviceCell(value),
    },{
        Header: "Created At",
        accessor: 'createdAt',
        sort: 'ASC',
        Cell: ({value}) => value ? formatDateTime(value) : ''
    }];

    return (
        <div className="central-meta">
            <div className="editing-info">
                <h5 className="f-title"><i className="ti-dashboard" /> Main Dashboard</h5>
                <MainStatistics />
                <br/>
                <h3>Tracking</h3>
                <ReactTable
                    ref={tableRef}
                    columns={columns}
                    api={'tracking.list'}
                    onError={handleError}
                    defaultSorted={[{
                        id: 'createdAt',
                        desc: true
                    }]}
                />
                <br/>
                <h3>Admin Tracking</h3>
                <ReactTable
                    ref={tableRef}
                    columns={columns}
                    api={'tracking.list'}
                    apiParam={{admin: true}}
                    onError={handleError}
                    defaultSorted={[{
                        id: 'createdAt',
                        desc: true
                    }]}
                />
            </div>
        </div>
    );
}

export default Dashboard;
