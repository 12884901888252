import React from 'react';

export const CellRenderer = (value, fn, promotions = false) => {
	const val = fn ? fn(value) : value;
	if(promotions) {
		return (<div 
			className={`status-orb ${val ? 'status-orb-inactive' : 'status-orb-active'}`}
			title={val ? 'Disabled' : 'Active'}
		/>);
	} else {
		return (<div 
			className={`status-orb ${val ? 'status-orb-active' : 'status-orb-inactive'}`}
			title={val ? 'Active' : 'Inactive'}
		/>);
	}
};

export const FilterRenderer = ({ filter, onChange }) => {
	const value = filter && filter.value;
	const toggle = {undefined: true, true: false, false: undefined};
	const classes = {undefined: '', true: 'status-orb-active', false: 'status-orb-inactive'};
	return (
		<div 
			className={`status-orb status-orb-filter ${classes[value]}`}
			onClick={() => onChange(toggle[value])}
		/>
	);
};

export const statusColumn = (fn) => ({
	Header: '',
	accessor: 'Access',
	width: 30,
	resizable: false,
	Cell: ({ value }) => CellRenderer(value, fn),
	Filter: FilterRenderer
})

export const promoStatusColumn = (fn) => ({
	Header: '',
	accessor: 'disabled',
	width: 30,
	resizable: false,
	Cell: ({ value }) => CellRenderer(value, fn, true),
	Filter: FilterRenderer
})