import React, { useRef, useEffect } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import { formatDateTime } from '../../Util/common';
import { saveTrackingData } from '../../Services/networkService';
import { useUserContext } from '../../Contexts/userContext';

export default function Groups(){
  const {t} = useTranslation('common');
  const { handleError } = usePopupContext();
  const { user } = useUserContext();
  const tableRef = useRef();

  const columns = [{
    Header: 'Timestamp',
    accessor: 'createdAt',
    Cell: ({ value }) => formatDateTime(value),
    width: 180
  },{
    Header: 'User ID',
    accessor: 'user._id'
  },{
    Header: 'Status',
    accessor: 'status',
    width: 50
  },{
    Header: 'Message',
    accessor: 'message'
  },{
    Header: 'Url',
    accessor: 'url'
  },{
    Header: 'Method',
    accessor: 'method',
    width: 60
  }];

  const actions = [];

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/exceptions')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-archive" /> Exceptions</h5>

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.errors.list'}
          disableFilters
          onError={handleError}
          defaultSorted={[{
            id: 'createdAt',
            desc: true
          }]}
        />

      </div>
    </div>
  );
}
