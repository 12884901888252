import React, { useEffect, useState, useRef } from 'react';
import LoadingButon from '../../../../Components/LoadingButton';
import HeaderButton from './button';
import { Field } from 'react-final-form';
import TextField from '../../../../Components/Form/TextField';
import { usePopupContext } from '../../../../Contexts/popupContext';
import { request } from '../../../../Services/networkService';

const Header = ({page, setPage, setPageTitle}) => {
    const { handleError, popupMessageOpen, popupConfirmOpen, popupFormOpen } = usePopupContext();
    const [buttons, setButtons] = useState([{
        title: 'Memorials',
        icon: 'ti-pencil-alt',
        id: 0
    }])
    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        dragItem.current = position;
    }

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    }

    const drop = (e) => {
        const copyListItems = [...buttons];
        const dragItemContent = copyListItems[dragItem.current];
        const dragOverItemContent = copyListItems[dragOverItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);

        // To Server: updating positions
        updateButtonPosition(dragItemContent['id'], dragOverItem.current);
        updateButtonPosition(dragOverItemContent['id'], dragItem.current);

        // Clear positions
        dragItem.current = null;
        dragOverItem.current = null;

        // Setting the new buttons order
        setButtons(copyListItems);
    };

    const updateButtonPosition = (id, position) => {
        request('admin.updateFeatures', { id, position })
        .catch(err => handleError(err))
    }

    const listPages = () => {
        request('public.listFeatures')
        .then(res => setButtons(current => [...current, ...res]))
        .catch((err) => handleError(err))
    }

    const pageFormPopup = (editMode, o) => {

        return (
          <div className="form-row">
            <div className='form-group'>
              <Field component={TextField}
               name="title"
               type="text"
               placeholder="Page Name"
              />
            </div>
          </div>
        )
    };

    const newPage = () => {
        let body = {}
        popupFormOpen('Create Page', pageFormPopup())
        .then(result => {
          if(result) {
            body = {
                title: result.title,
                icon: 'ti-file',
                text: '',
                position: buttons.length
            };

            request('admin.updateFeatures', body)
            .then(res => {
                setButtons(current => [...current, res])
                popupMessageOpen('Message', 'The Page is successfully created')
            })
            .catch((err) => handleError(err))
          }
        })
    }

    const deletePage = (id) => {
        popupConfirmOpen('Delete confirmation', 'Are you sure you want to delete this page?')
        .then(result => {
            request('admin.deleteFeature', {id})
            .then(res => {
                setButtons(current => current.filter((item) => item.id !== id));
                setPage(0);
                setPageTitle('Memorials');
            })
            .catch((err) => handleError(err))
        })
    }

    useEffect(() => {
        listPages();
    }, [])

    return (
        <div className='managerHeader'>
            <div className='btnContainer'>
                {
                    buttons.map((button, i) => 
                        button.id !== 0 ?
                        <HeaderButton key={i} title={button.title} icon={button.icon} active={page === button.id ? true : false} click={() => {
                            setPage(button.id);
                            setPageTitle(button.title);
                        }} draggable onDragStart={(e) => dragStart(e, i)} onDragEnter={(e) => dragEnter(e, i)} onDragEnd={() => drop()} />
                        :
                        <HeaderButton key={i} title={button.title} icon={button.icon} active={page === button.id ? true : false} click={() => {
                            setPage(button.id);
                            setPageTitle(button.title);
                        }}/>
                    )
                }
            </div>
            <div className="submit-btns text-center">
                {
                    page !== 0 && <LoadingButon onClick={() => deletePage(page)} iconCls='ti-trash' title="Delete Page" />
                }
                <LoadingButon onClick={newPage} iconCls='ti-plus' title="New Page" />
            </div>
        </div>
    );
}

export default Header;
