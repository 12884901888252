import React from 'react';

const HeaderButton = ({title, icon, click, active = false, draggable = false, onDragStart = () => {}, onDragEnter = () => {}, onDragEnd = () => {}}) => {
    return (
        <button type='button' onClick={click} className={active ? 'mBtn active' : 'mBtn'} draggable={draggable} onDragStart={onDragStart} onDragEnter={onDragEnter} onDragEnd={onDragEnd}>
            <i className={icon}></i>&nbsp;<p>{title}</p>
        </button>
    );
}

export default HeaderButton;
