import moment from 'moment';
import { CONFIG } from '../Config';
import { request } from '../Services/networkService';

export const european_countries = [
  {"name": "Andorra", "id": "AD"},
  {"name": "Albania", "id": "AL"},
  {"name": "Austria", "id": "AT"},
  {"name": "Åland Islands", "id": "AX"},
  {"name": "Bosnia and Herzegovina", "id": "BA"},
  {"name": "Belgium", "id": "BE"},
  {"name": "Bulgaria", "id": "BG"},
  {"name": "Belarus", "id": "BY"},
  {"name": "Switzerland", "id": "CH"},
  {"name": "Cyprus", "id": "CY"},
  {"name": "Czech Republic", "id": "CZ"},
  {"name": "Germany", "id": "DE"},
  {"name": "Denmark", "id": "DK"},
  {"name": "Estonia", "id": "EE"},
  {"name": "Spain", "id": "ES"},
  {"name": "Finland", "id": "FI"},
  {"name": "Faroe Islands", "id": "FO"},
  {"name": "France", "id": "FR"},
  {"name": "United Kingdom", "id": "GB"},
  {"name": "Guernsey", "id": "GG"},
  {"name": "Greece", "id": "GR"},
  {"name": "Croatia", "id": "HR"},
  {"name": "Hungary", "id": "HU"},
  {"name": "Ireland", "id": "IE"},
  {"name": "Isle of Man", "id": "IM"},
  {"name": "Iceland", "id": "IC"},
  {"name": "Italy", "id": "IT"},
  {"name": "Jersey", "id": "JE"},
  {"name": "Liechtenstein", "id": "LI"},
  {"name": "Republic of Lithuania", "id": "LT"},
  {"name": "Luxembourg", "id": "LU"},
  {"name": "Latvia", "id": "LV"},
  {"name": "Monaco", "id": "MC"},
  {"name": "Republic of Moldova", "id": "MD"},
  {"name": "Macedonia, The Former Yugoslav Republic of", "id": "MK"},
  {"name": "Malta", "id": "MT"},
  {"name": "Netherlands", "id": "NL"},
  {"name": "Norway", "id": "NO"},
  {"name": "Poland", "id": "PL"},
  {"name": "Portugal", "id": "PT"},
  {"name": "Romania", "id": "RO"},
  {"name": "Russian Federation", "id": "RU"},
  {"name": "Sweden", "id": "SE"},
  {"name": "Slovenia", "id": "SI"},
  {"name": "Svalbard and Jan Mayen", "id": "SJ"},
  {"name": "Slovakia", "id": "SK"},
  {"name": "San Marino", "id": "SM"},
  {"name": "Ukraine", "id": "UA"},
  {"name": "Holy See (Vatican City State)", "id": "VA"}
]

export const us_states = [
  {"name": "Alabama", "id": "AL"},
  {"name": "Alaska", "id": "AK"},
  {"name": "American Samoa", "id": "AS"},
  {"name": "Arizona", "id": "AZ"},
  {"name": "Arkansas", "id": "AR"},
  {"name": "California", "id": "CA"},
  {"name": "Colorado", "id": "CO"},
  {"name": "Connecticut", "id": "CT"},
  {"name": "Delaware", "id": "DE"},
  {"name": "District Of Columbia", "id": "DC"},
  {"name": "Federated States Of Micronesia", "id": "FM"},
  {"name": "Florida", "id": "FL"},
  {"name": "Georgia", "id": "GA"},
  {"name": "Guam", "id": "GU"},
  {"name": "Hawaii", "id": "HI"},
  {"name": "Idaho", "id": "ID"},
  {"name": "Illinois", "id": "IL"},
  {"name": "Indiana", "id": "IN"},
  {"name": "Iowa", "id": "IA"},
  {"name": "Kansas", "id": "KS"},
  {"name": "Kentucky", "id": "KY"},
  {"name": "Louisiana", "id": "LA"},
  {"name": "Maine", "id": "ME"},
  {"name": "Marshall Islands", "id": "MH"},
  {"name": "Maryland", "id": "MD"},
  {"name": "Massachusetts", "id": "MA"},
  {"name": "Michigan", "id": "MI"},
  {"name": "Minnesota", "id": "MN"},
  {"name": "Mississippi", "id": "MS"},
  {"name": "Missouri", "id": "MO"},
  {"name": "Montana", "id": "MT"},
  {"name": "Nebraska", "id": "NE"},
  {"name": "Nevada", "id": "NV"},
  {"name": "New Hampshire", "id": "NH"},
  {"name": "New Jersey", "id": "NJ"},
  {"name": "New Mexico", "id": "NM"},
  {"name": "New York", "id": "NY"},
  {"name": "North Carolina", "id": "NC"},
  {"name": "North Dakota", "id": "ND"},
  {"name": "Northern Mariana Islands", "id": "MP"},
  {"name": "Ohio", "id": "OH"},
  {"name": "Oklahoma", "id": "OK"},
  {"name": "Oregon", "id": "OR"},
  {"name": "Palau", "id": "PW"},
  {"name": "Pennsylvania", "id": "PA"},
  {"name": "Puerto Rico", "id": "PR"},
  {"name": "Rhode Island", "id": "RI"},
  {"name": "South Carolina", "id": "SC"},
  {"name": "South Dakota", "id": "SD"},
  {"name": "Tennessee", "id": "TN"},
  {"name": "Texas", "id": "TX"},
  {"name": "Utah", "id": "UT"},
  {"name": "Vermont", "id": "VT"},
  {"name": "Virgin Islands", "id": "VI"},
  {"name": "Virginia", "id": "VA"},
  {"name": "Washington", "id": "WA"},
  {"name": "West Virginia", "id": "WV"},
  {"name": "Wisconsin", "id": "WI"},
  {"name": "Wyoming", "id": "WY"}
]

export const north_america_countries = [
  {"id": "AC", "name": "Acoma"},
  {"id": "AU", "name": "Aleutian"},
  {"id": "AL", "name": "Algonquin"},
  {"id": "AM", "name": "American"},
  {"id": "AN", "name": "Antigua and Barbuda"},
  {"id": "AT", "name": "Antilles"},
  {"id": "AR", "name": "Arikara"},
  {"id": "AB", "name": "Aruba"},
  {"id": "AZ", "name": "Aztec"},
  {"id": "BB", "name": "Barbados"},
  {"id": "BC", "name": "Bella Coola"},
  {"id": "BL", "name": "Blackfoot"},
  {"id": "BO", "name": "Bonaire"},
  {"id": "CA", "name": "Canada"},
  {"id": "CG", "name": "Canada/Greenland"},
  {"id": "CE", "name": "Cherokee"},
  {"id": "CY", "name": "Cheyenne"},
  {"id": "CH", "name": "Chickasaw"},
  {"id": "CI", "name": "Chinook"},
  {"id": "CO", "name": "Choktaw"},
  {"id": "CU", "name": "Chumash"},
  {"id": "BA", "name": "Commonwealth of the Bahamas"},
  {"id": "CR", "name": "Creek"},
  {"id": "CB", "name": "Cuba"},
  {"id": "DA", "name": "Dakota"},
  {"id": "DO", "name": "Dominica"},
  {"id": "DR", "name": "Dominican Republic"},
  {"id": "ES", "name": "Eskimo"},
  {"id": "EK", "name": "Eskimo (Greenland)"},
  {"id": "GL", "name": "Greenland"},
  {"id": "GR", "name": "Grenada"},
  {"id": "HA", "name": "Haiti"},
  {"id": "HO", "name": "Hopi"},
  {"id": "IN", "name": "Inuit"},
  {"id": "IR", "name": "Iroquois"},
  {"id": "JM", "name": "Jamaica"},
  {"id": "KL", "name": "Klamath"},
  {"id": "KW", "name": "Kwakiutl"},
  {"id": "LA", "name": "Lakota"},
  {"id": "MA", "name": "Mandan"},
  {"id": "ME", "name": "Mexico"},
  {"id": "NV", "name": "Navajo"},
  {"id": "OH", "name": "Ohlone"},
  {"id": "OJ", "name": "Ojibwe"},
  {"id": "OS", "name": "Osage"},
  {"id": "PW", "name": "Pawnee"},
  {"id": "PE", "name": "Pequot"},
  {"id": "PP", "name": "Popoluca"},
  {"id": "PO", "name": "Potawatomi"},
  {"id": "PU", "name": "Pueblo"},
  {"id": "PR", "name": "Puerto Rico"},
  {"id": "SK", "name": "Saint Kitts and Nevis"},
  {"id": "SL", "name": "Saint Lucia"},
  {"id": "SV", "name": "Saint Vincent and the Grenadines"},
  {"id": "SA", "name": "Salish"},
  {"id": "SE", "name": "Seneca"},
  {"id": "SH", "name": "Shoshoni"},
  {"id": "SX", "name": "Sioux"},
  {"id": "TL", "name": "Tlingit"},
  {"id": "US", "name": "United States"},
  {"id": "VI", "name": "Virgin Islands"},
  {"id": "YU", "name": "Yurok"},
  {"id": "ZU", "name": "Zuni"}
]

export const south_america_countries = [
  {"id": "AC", "name": "Araucanian"},
  {"id": "AR", "name": "Argentina"},
  {"id": "BE", "name": "Belize"},
  {"id": "BO", "name": "Bolivia"},
  {"id": "RR", "name": "Bororo"},
  {"id": "BR", "name": "Brazil"},
  {"id": "CH", "name": "Chile"},
  {"id": "CI", "name": "Chimalateco"},
  {"id": "CO", "name": "Colombia"},
  {"id": "CR", "name": "Costa Rica"},
  {"id": "EC", "name": "Ecuador"},
  {"id": "ES", "name": "El Salvador"},
  {"id": "FI", "name": "Falkland Islands"},
  {"id": "FG", "name": "French Guiana"},
  {"id": "GU", "name": "Guatemala"},
  {"id": "GY", "name": "Guyana"},
  {"id": "HO", "name": "Honduras"},
  {"id": "IN", "name": "Inca"},
  {"id": "MA", "name": "Mapuche"},
  {"id": "MY", "name": "Mayan"},
  {"id": "NA", "name": "Nahuatl"},
  {"id": "NI", "name": "Nicaragua"},
  {"id": "PM", "name": "Panama"},
  {"id": "PA", "name": "Paraguay"},
  {"id": "PE", "name": "Peru"},
  {"id": "SU", "name": "Suriname"},
  {"id": "TT", "name": "Trinidad and Tobago"},
  {"id": "TP", "name": "Tupi"},
  {"id": "UR", "name": "Uruguay"},
  {"id": "VE", "name": "Venezuela"}
]

export const afrika_countries = [
  {"id": "AI", "name": "Afrikaans"},
  {"id": "AL", "name": "Algeria"},
  {"id": "AN", "name": "Angola"},
  {"id": "BA", "name": "Bantu"},
  {"id": "BE", "name": "Benin"},
  {"id": "BT", "name": "Botswana"},
  {"id": "BF", "name": "Burkina Faso (Upper Volta)"},
  {"id": "BR", "name": "Burundi"},
  {"id": "BU", "name": "Bushman"},
  {"id": "BH", "name": "Bushongo"},
  {"id": "CR", "name": "Cameroon"},
  {"id": "CI", "name": "Canary Is."},
  {"id": "CV", "name": "Cape Verde"},
  {"id": "CA", "name": "Central African Republic"},
  {"id": "DH", "name": "Dahomean"},
  {"id": "DC", "name": "Democratic Republic of the Congo"},
  {"id": "EG", "name": "Egypt"},
  {"id": "ER", "name": "Eritrea"},
  {"id": "ET", "name": "Ethiopia"},
  {"id": "FT", "name": "Fula/Toucouleur"},
  {"id": "GB", "name": "Gabon"},
  {"id": "GA", "name": "Gambia"},
  {"id": "GH", "name": "Ghana"},
  {"id": "GC", "name": "Gold Coast"},
  {"id": "GU", "name": "Guinea"},
  {"id": "HO", "name": "Hottentot"},
  {"id": "IC", "name": "Ivory Coast"},
  {"id": "KY", "name": "Kenya"},
  {"id": "LE", "name": "Lesotho"},
  {"id": "LI", "name": "Liberia"},
  {"id": "LB", "name": "Libya"},
  {"id": "MD", "name": "Madagascar"},
  {"id": "MW", "name": "Malawi"},
  {"id": "ML", "name": "Mali"},
  {"id": "MN", "name": "Mande"},
  {"id": "MU", "name": "Mauritania"},
  {"id": "MA", "name": "Mauritius"},
  {"id": "MB", "name": "Mbundu"},
  {"id": "ME", "name": "Mende"},
  {"id": "MR", "name": "Morocco"},
  {"id": "MZ", "name": "Mozambique"},
  {"id": "NM", "name": "Namibia"},
  {"id": "NG", "name": "Niger"},
  {"id": "NI", "name": "Nigeria"},
  {"id": "PY", "name": "Pygmy"},
  {"id": "CH", "name": "Republic of Chad"},
  {"id": "RD", "name": "Republic of Djibouti"},
  {"id": "RE", "name": "Republic of Equatorial Guinea"},
  {"id": "RG", "name": "Republic of Guinea-Bissau"},
  {"id": "RS", "name": "Republic of Sao Tome and Principe"},
  {"id": "SY", "name": "Republic of Seychelles"},
  {"id": "RC", "name": "Republic of the Congo (Congo-Brazzaville)"},
  {"id": "RI", "name": "Reunion Island"},
  {"id": "RW", "name": "Rwanda"},
  {"id": "SX", "name": "Saint Helena Island"},
  {"id": "SE", "name": "Semitic"},
  {"id": "SN", "name": "Senegal"},
  {"id": "SL", "name": "Sierra Leone"},
  {"id": "SO", "name": "Somalia"},
  {"id": "SA", "name": "South Africa"},
  {"id": "SS", "name": "South Sudan"},
  {"id": "SU", "name": "Sudan"},
  {"id": "SH", "name": "Swahili"},
  {"id": "SW", "name": "Swaziland"},
  {"id": "TA", "name": "Tanzania"},
  {"id": "TO", "name": "Togo"},
  {"id": "TN", "name": "Tunisia"}
]

export const asia_countries = [
  {"id": "AH", "name": "Abkhazian"},
  {"id": "AF", "name": "Afghanistan"},
  {"id": "AI", "name": "Ainu"},
  {"id": "AK", "name": "Akkadian (Accadian)"},
  {"id": "AL", "name": "Altai"},
  {"id": "AR", "name": "Arabian"},
  {"id": "AM", "name": "Armenian"},
  {"id": "AY", "name": "Assyrian"},
  {"id": "AB", "name": "Assyro-Babylonian"},
  {"id": "AZ", "name": "Azerbaijan"},
  {"id": "BY", "name": "Babylon"},
  {"id": "BI", "name": "Bahrain"},
  {"id": "BA", "name": "Bangladesh"},
  {"id": "BE", "name": "Bengal"},
  {"id": "BH", "name": "Bhutan"},
  {"id": "BO", "name": "Borneo"},
  {"id": "BN", "name": "Brunei"},
  {"id": "BR", "name": "Buriat"},
  {"id": "BU", "name": "Burma"},
  {"id": "CM", "name": "Cambodia"},
  {"id": "CH", "name": "China"},
  {"id": "CU", "name": "Chukchi"},
  {"id": "DK", "name": "Democratic People's Republic of Korea"},
  {"id": "EL", "name": "Elamite"},
  {"id": "EV", "name": "Evenki"},
  {"id": "GE", "name": "Georgia"},
  {"id": "HA", "name": "Hattic"},
  {"id": "HE", "name": "Hebrew"},
  {"id": "HI", "name": "Hindu"},
  {"id": "HT", "name": "Hittite"},
  {"id": "IN", "name": "India"},
  {"id": "ID", "name": "Indonesia"},
  {"id": "IR", "name": "Iran"},
  {"id": "IQ", "name": "Iraq"},
  {"id": "IS", "name": "Israel"},
  {"id": "IT", "name": "Itelmen"},
  {"id": "JA", "name": "Japan"},
  {"id": "JW", "name": "Jewish"},
  {"id": "JO", "name": "Jordan"},
  {"id": "KA", "name": "Kashmir"},
  {"id": "KZ", "name": "Kazakhstan"},
  {"id": "KT", "name": "Ket"},
  {"id": "KR", "name": "Korea"},
  {"id": "KU", "name": "Kuwait"},
  {"id": "KY", "name": "Kyrgyzstan"},
  {"id": "LA", "name": "Laos"},
  {"id": "LE", "name": "Lebanon"},
  {"id": "MA", "name": "Malaysia"},
  {"id": "MS", "name": "Mansi"},
  {"id": "ME", "name": "Mesopotamian"},
  {"id": "MY", "name": "Minyong"},
  {"id": "MO", "name": "Mongolia"},
  {"id": "MG", "name": "Monguor"},
  {"id": "MM", "name": "Myanmar"},
  {"id": "NA", "name": "Nanai"},
  {"id": "NG", "name": "Neghidhian"},
  {"id": "NE", "name": "Nepal"},
  {"id": "NS", "name": "Nganasan"},
  {"id": "OM", "name": "Oman"},
  {"id": "OS", "name": "Ostyak"},
  {"id": "OT", "name": "Ottoman"},
  {"id": "PK", "name": "Pakistan"},
  {"id": "PE", "name": "Persian"},
  {"id": "PH", "name": "Philippines"},
  {"id": "PO", "name": "Phoenician"},
  {"id": "QA", "name": "Qatar"},
  {"id": "RK", "name": "Republic of Korea"},
  {"id": "ML", "name": "Republic of the Maldives"},
  {"id": "SA", "name": "Sanskrit"},
  {"id": "SB", "name": "Saudi Arabia"},
  {"id": "SC", "name": "Scythian"},
  {"id": "SE", "name": "Semitic"},
  {"id": "SI", "name": "Siberia"},
  {"id": "SR", "name": "Sri Lanka"},
  {"id": "SU", "name": "Sumerian"},
  {"id": "SY", "name": "Syria"},
  {"id": "TW", "name": "Taiwan"},
  {"id": "TJ", "name": "Tajik"},
  {"id": "TS", "name": "Tajikistan"},
  {"id": "TM", "name": "Tamil"},
  {"id": "TH", "name": "Thailand"},
  {"id": "TB", "name": "Tibet"},
  {"id": "TL", "name": "Timor-Leste"},
  {"id": "TN", "name": "Tungu"},
  {"id": "TU", "name": "Turkey"},
  {"id": "TK", "name": "Turkmenistan"},
  {"id": "TV", "name": "Tuva"},
  {"id": "UL", "name": "Ulci"},
  {"id": "UA", "name": "United Arab Emirates"},
  {"id": "UR", "name": "Urartu"},
  {"id": "UZ", "name": "Uzbekistan"},
  {"id": "VT", "name": "Vietnam"},
  {"id": "YK", "name": "Yakutian"},
  {"id": "YE", "name": "Yemen"}
]

export const oceania_countries = [
  {"id": "AU", "name": "Australia"},
  {"id": "CI", "name": "Caroline Is."},
  {"id": "CO", "name": "Cook Islands"},
  {"id": "FJ", "name": "Fiji"},
  {"id": "FP", "name": "French Polynesia"},
  {"id": "GM", "name": "Guam"},
  {"id": "HA", "name": "Hawaii"},
  {"id": "KI", "name": "Kiribati"},
  {"id": "MA", "name": "Marquesas Islands"},
  {"id": "MI", "name": "Marshall Is."},
  {"id": "ME", "name": "Melanesia"},
  {"id": "MC", "name": "Micronesia"},
  {"id": "NA", "name": "Nauru"},
  {"id": "NB", "name": "New Britain"},
  {"id": "NC", "name": "New Caledonia"},
  {"id": "GU", "name": "New Guinea"},
  {"id": "NZ", "name": "New Zealand"},
  {"id": "NI", "name": "Niue"},
  {"id": "PN", "name": "Papua New Guinea"},
  {"id": "PO", "name": "Polynesia"},
  {"id": "PA", "name": "Republic of Palau"},
  {"id": "SA", "name": "Samoa"},
  {"id": "SI", "name": "Society Is."},
  {"id": "SO", "name": "Solomon Islands"},
  {"id": "TO", "name": "Tonga"},
  {"id": "TU", "name": "Tuamotu"},
  {"id": "TV", "name": "Tuvalu"},
  {"id": "--", "name": "Unknown"},
  {"id": "VA", "name": "Vanuatu"},
  {"id": "YO", "name": "Yolngu"}
]

export const arrayEqual = (arr1, arr2) => arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);

// this function finds a value from a deep object based on its string representation
export function resolveObj(str, obj){
  return str && obj ? str.split('.').reduce((o,i)=> o ? o[i] : false, obj) : null;
} 

export function formatDateTime(str) {
  const locale = window.navigator.userLanguage || window.navigator.language;
  return moment(str).locale(locale).format("L HH:mm");
}

export function formatDate(str) {
  const locale = window.navigator.userLanguage || window.navigator.language;
  return moment(str).locale(locale).format("L");
}

export function userIsAdmin(user) {
  return user.AccessLevel === 3;
}

export function formatCurrency(num) {
  return `$${num ? num.toFixed(2) : "0.00"}`;
}

export function userRole(accessLevel) {
  switch(accessLevel) {
    case 1: return 'Group Admin';
    case 2: return 'Global Moderator';
    case 3: return 'Global Admin';
    default: return 'User';
  }
}

export function getImageUrl(str) {
  return str ? 
    (CONFIG.S3_BUCKET ? `https://${CONFIG.S3_BUCKET}.s3.amazonaws.com/${str}` : `${CONFIG.BACKEND_URL}/images/${str}`) :
    `${process.env.PUBLIC_URL}/images/image-not-found.png`;
}

export function getName(u) {
  return u.Name || (u._type === 'honoree' ?
    `${u.FirstName} ${u.Nickname ? `(${u.Nickname})` : ''} ${u.MiddleName ? u.MiddleName : ''} ${u.HonoreeType === 'Pet' ? '(' : ''}${u.LastName}${u.HonoreeType === 'Pet' ? ')' : ''}`
    :
    (u.DisplayName ? u.DisplayName : `${u.First_Name} ${u.Last_Name}`));
}

export function logError(err, user){
  const res = err?.response || {};
  const data = {
    stack: Error().stack,
    user: user?._id,
    message: err && err.response && err.response.data ? err.response.data.message : (err?.message ? err.message : (err ? err.toString() : 'Unknown error')),
    statusCode: res.data?.statusCode,
    status: res.status,
    statusText: res.statusText,
    url: res.config?.url,
    method: res.config?.method,
    sendMail: CONFIG.sendExceptionMail ? true : false
  };
  request('public.logError', data);
};

export function loadImagePreview(upload) {
  return new Promise((resolve, reject) => {
    if (upload) {
      const reader = new FileReader();
      reader.readAsDataURL(upload);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    } else {
      resolve(null);
    }
  })
};

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export function animateNumber(obj, start, end, duration) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.innerHTML = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

// animateNumber(obj, 0, 100, 5000);

export const addLog = (logData, user) => {
  const data = {
      type: logData.type,
      title: logData.title,
      message: logData.message,
      user: {
          id: user._id,
          fName: user.First_Name,
          lName: user.Last_Name,
          email: user.Email
      },
  };

  return request('admin.logs.create', data);
}