import React, { Fragment, useState, useRef, useContext, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../Components/PaymentForm';
import Cropper from '../Components/Cropper';
import { CONFIG } from '../Config';
import { useTranslation } from "react-i18next";
import { useUserContext } from './userContext';
import { logError } from '../Util/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import smiley from '../assets/images/smiley-sticker.svg';

const PopupContext = React.createContext();
export const usePopupContext = () => useContext(PopupContext);

export function PopupProvider(props) {
  const {t} = useTranslation('common');
  const { user } = useUserContext();
  const [openState, setOpenState] = useState(false);
  const [options, setOptions] = useState();
  const [stripe, setStripe] = useState();
  const awaitingPromiseRef = useRef();

  useEffect(() => {
    setStripe(loadStripe(CONFIG.STRIPE_API_KEY));
  }, []);

  const popupOpen = (options) => {
    setOptions(options);
    setOpenState(true);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const popupClose = data => {
    awaitingPromiseRef.current && awaitingPromiseRef.current.reject(data);
    awaitingPromiseRef.current = null;
    setOpenState(false);
  };

  const popupCloseAndDelete = data => {
    data.delete = true;
    popupSubmit(data);
  }

  const popupSubmit = data => {
    awaitingPromiseRef.current && awaitingPromiseRef.current.resolve(data);
    awaitingPromiseRef.current = null;
    setOpenState(false);
  };

  const popupConfirmOpen = (title, text, data, content) => {
    const popupOptions = {
      title: title,
      closeReject: true,
      content: content || (
        <Fragment>
          <Modal.Body className="text-center">
            <img width="64px" alt="Smiley" src={smiley} />
            <h3>{text}</h3>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => popupSubmit(data)}>{t('popupcontext.y')}</Button>
            <Button onClick={popupClose}>{t('popupcontext.n')}</Button>
          </Modal.Footer>
        </Fragment>
      )
    }
    return popupOpen(popupOptions);
  }

  const popupMessageOpen = (title, text, extraBtns) => {
    const popupOptions = {
      title: title,
      closeReject: false,
      content: (
        <Fragment>
          <Modal.Body className="text-center">
            <img width="64px" alt="Smiley" src={smiley} />
            <h3>{text}</h3>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={popupSubmit}>{t('popupcontext.k')}</Button>
            {extraBtns}
          </Modal.Footer>
        </Fragment>
      )
    }
    return popupOpen(popupOptions);
  }

  const popupErrorOpen = (text) => {
    const popupOptions = {
      title: text,
      closeReject: false,
      content: (
        <Fragment>
          <Modal.Body className="text-center">
          <img width="64px" alt="Smiley" src={smiley} />
          <h6>
            {t('popupcontext.error1')} <br/>
            {t('popupcontext.error2')} <br/>
            {t('popupcontext.error3')}
          </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={popupSubmit}>{t('popupcontext.k')}</Button>
          </Modal.Footer>
        </Fragment>
      )
    }
    return popupOpen(popupOptions);
  }

  const popupFormOpen = (title, content, formOptions, fullContent, editable) => {
    const popupOptions = {
      title: title,
      closeReject: true,
      content: (
        <Form
          {...formOptions}
          onSubmit={popupSubmit}
          render={({ handleSubmit, submitting, values}) => {
            return (
              // values.ad && formOptions.modules ?
              formOptions && formOptions.modules ?
              <form onSubmit={handleSubmit}>
                {fullContent || <Modal.Body>
                  <ReactQuill 
                    value={values.text}
                    theme="snow"
                    name="text"
                    label="Text"
                    modules={formOptions.modules}
                    onChange={e => values.text = e}
                  />
                  { content }
                  <div className="popup-form-btns">
                    <Button type="submit">{t('popupcontext.k')}</Button>
                    <Button type="button" onClick={popupClose}>{t('popupcontext.cancel')}</Button>
                    {
                      editable ?
                        <Button type="button" className='deleteButton' onClick={() => popupCloseAndDelete(formOptions.initialValues)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                      :null
                    }
                  </div>
                </Modal.Body>
              }
              </form>
              :
              <form onSubmit={handleSubmit}>
                {fullContent || <Modal.Body>
                  { content }
                  <div className="popup-form-btns">
                    <Button type="submit">{t('popupcontext.k')}</Button>
                    <Button type="button" onClick={popupClose}>{t('popupcontext.cancel')}</Button>
                    {
                      editable ?
                        <Button type="button" className='deleteButton' onClick={() => popupCloseAndDelete(formOptions.initialValues)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                      :null
                    }
                  </div>
                </Modal.Body>
              }
              </form>
          )}}
        />
      )
    }
    return popupOpen(popupOptions);
  };

  const popupTwoFAOpen = (title, data, content, type) => {
    const popupOptions = {
      title: title,
      closeReject: true,
      content: (
        <Fragment>
          <Modal.Body className="text-center">
          {
            type === 'google' ?
            <>
              <h6>
                Scan the QR code with<br/>
                the Google Authenticator App<br/>
              </h6>
              <img alt='qrCode' src={data.data}/>
            </>
            :
            type === 'delete' ?
            <h6>
                Verify the code to<br/>
                deactivate this 2FA method.<br/>
            </h6>
            :
            <h6>
                We've sended the code<br/>
                to your email<br/>
            </h6>
          }
          <Form
            onSubmit={popupSubmit}
            render={({ handleSubmit, submitting, values}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Modal.Body className='text-left'>
                    { content }
                  </Modal.Body>
                  <Modal.Footer>
                    <Button type='submit'>{t('popupcontext.k')}</Button>
                  </Modal.Footer>
                </form>
            )}}
          />
          </Modal.Body>
        </Fragment>
      )
    }
    return popupOpen(popupOptions);
  }

  const popupChooseTwoFAOpen = (title, content) => {
    const popupOptions = {
      title: title,
      closeReject: true,
      content: (
        <Fragment>
          <Modal.Body className="text-center">
          <h6>{t('profile.selectSetting')}</h6>
          <Form
            onSubmit={popupSubmit}
            render={({ handleSubmit, submitting, values}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Modal.Body className='text-left'>
                    { content }
                  </Modal.Body>
                  <Modal.Footer>
                    <Button type='submit'>{t('popupcontext.k')}</Button>
                  </Modal.Footer>
                </form>
            )}}
          />
          </Modal.Body>
        </Fragment>
      )
    }
    return popupOpen(popupOptions);
  }

  const popupFeedbackOpen = (title) => {
    const popupOptions = {
      title: title,
      closeReject: true,
      content: (
        <Fragment>
          <Modal.Body className='text-center'>
            <iframe frameBorder="0" id="iframeX6D3A1V8A8F1F9Y6J" src="https://www.survio.com/survey/i/Q8M7Y9N4L6Y8G8U5L" height="600" width="800"></iframe>
          </Modal.Body>
        </Fragment>
      )
    }
    return popupOpen(popupOptions)
  }

  const popupPaymentOpen = (options) => {
    const popupOptions = {
      title: t('popupcontext.payment'),
      closeReject: true,
      content: (
        <Elements stripe={stripe}>
          <PaymentForm onClose={popupClose} {...options} />
        </Elements>
      )
    }
    return popupOpen(popupOptions);
  };

  const popupCropperOpen = (options) => {
    const popupOptions = {
      title: 'Cropper',
      closeReject: true,
      content: (<Cropper {...options} />)
    }
    return popupOpen(popupOptions)
  };

  const handleError = err => {
    const text = err ? 
    (err.response && err.response.data.statusCode != null ? t(`statusCodes.${err.response.data.statusCode}`) : (err.response ? err.response.data.message : err.message))
    : t('popupcontext.error');
    if (err && err.response && err.response.data.type === 'message') {
      popupMessageOpen(t('popupcontext.msg'), text);
    } else {
      logError(err, user);
      popupErrorOpen(text);
    }
  };

  return (
    <PopupContext.Provider value={{ popupOpen, popupConfirmOpen, popupFormOpen, popupTwoFAOpen, popupChooseTwoFAOpen, popupFeedbackOpen, popupMessageOpen, popupPaymentOpen, popupCropperOpen, popupSubmit, popupClose, handleError }}>
      <Fragment>
        {props.children}
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={openState}
          onHide={options && options.closeReject ? popupClose : popupSubmit}
        >
          <Modal.Header closeButton>
            {openState && options && <h5>{options.title}</h5>}
          </Modal.Header>
          {openState && options && options.content}
        </Modal>
      </Fragment>
    </PopupContext.Provider>
  );
}
