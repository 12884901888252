import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';
import CheckBox from '../../Components/Form/CheckBox';
import { required, validateNumber } from '../../Util/form';
import { request, saveTrackingData } from '../../Services/networkService';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../Contexts/popupContext';
import { getRememberMe, setRememberMe } from '../../Util/storage';
import { useUserContext } from '../../Contexts/userContext';
import LoadingButton from '../../Components/LoadingButton';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import RadioGroup from '../../Components/Form/RadioGroup';
import { isAppAuthAvailable, isEmailAuthAvailable, userHasTwoFA, isTwoFAFull } from './TwoFAActions';
import { userIsAdmin } from '../../Util/common';

export default function Login() {
  const history = useHistory();
  const { handleError, popupMessageOpen, popupChooseTwoFAOpen } = usePopupContext();
  const {t} = useTranslation('common');
  const { user, setUser } = useUserContext();
  const [userInfo, setUserInfo] = useState();
  const [userTkn, setUserTkn] = useState('');
  const [TwoFA, setTwoFA] = useState(false);
  const [authType, setAuthType] = useState('');
  const [eToken, setEToken] = useState('');
  const hasError = new URLSearchParams(useLocation().search).get('error');

  useEffect(() => {
    hasError && popupMessageOpen('Error', 'Error registering with this account, probably already registered with the same username?')
  }, [hasError]);

  const chooseTwoFA = () => (
    <div className="form-row">
      <Field component={RadioGroup}
        name="authType"
        options={[{ name: 'Auth app', value: 'Google' }, { name: 'Email', value: 'Email' }]}
        validate={required}
        required
      />
    </div>
  );

  const resendMail = data => {
    return request('public.resendActivationMail', {Email: data.Email})
      .then(() => popupMessageOpen(t('popupcontext.msg'), t('statusCodes.12')))
      .catch(handleError)
  };

  const googleAuth = (data) => {
    setAuthType('Google');
    setTwoFA(true);
    setUserInfo({ ...data.user });
    saveTrackingData(data.user, '/admin/login')
    setUserTkn(data.token);
  }

  const emailAuth = (data) => {
    const selectedUser = data.user;
    const body = {
      id: selectedUser._id,
      fName: selectedUser.First_Name,
      lName: selectedUser.Last_Name,
      email: selectedUser.Email,
      secret: selectedUser.TwoFA.eToken,
      firstTime: false
    };

    request('user.generateTOTP', { id: body.id })
    .then(generated => {
      setEToken(generated.eToken);
      body.secret = generated.eToken;
      request('user.sendTOTP', body)
      .then(res => {
        setAuthType('Email');
        setTwoFA(true);
        setUserInfo({ ...data.user });
        saveTrackingData(selectedUser, '/admin/login')
        setUserTkn(data.token);
      })
      .catch((err) => handleError(err));
    })
    .catch((err) => handleError(err));
  }

  const onSubmit = data => {
    setRememberMe(data.remember);
    return request('user.login', data).then((data) => {
      if(userIsAdmin(data.user)) {
        if(isTwoFAFull(data.user.TwoFA)) {
          popupChooseTwoFAOpen('Auth app | Email', chooseTwoFA())
          .then(aType => {
            if(aType.authType === 'Google') googleAuth(data);
            else if(aType.authType === 'Email') emailAuth(data);
          })
          .catch(handleError);
        } else if(isAppAuthAvailable(data.user.TwoFA)) {
          googleAuth(data);
        } else if(isEmailAuthAvailable(data.user.TwoFA)) {
          emailAuth(data);
        } else {
          setUser({ ...data.user, token: data.token });
          saveTrackingData(data.user, '/admin/login')
          history.push({pathname: '/profile/twoFA'});
        }
      } else {
        popupMessageOpen('Message', t('statusCodes.2'))
      }
    }).catch(e => 
      e?.response?.data?.statusCode === 11 ? 
        popupMessageOpen(t('popupcontext.msg'), t('statusCodes.11'), (<Button onClick={() => resendMail(data)}>{t('popupcontext.resend')}</Button>) ) 
      : handleError(e));
  };

  const onSubmitTwoFA = data => {
    const eData = { eToken: eToken, otp: data.token };

    const gData = { gToken: userInfo.TwoFA.gToken, token: data.token };

    setRememberMe(data.remember);

    if(authType === 'Google') {
      return request('user.verifyGAuth', gData)
      .then(async (res) => {
        if(res) {
          await setUser({ ...userInfo, token: userTkn });
          history.push({pathname: '/admin'}) 
        } else {
          popupMessageOpen(t('popupcontext.msg'), t('statusCodes.20')) 
        }
      }).catch(e => 
        e?.response?.data?.statusCode === 11 ? 
          popupMessageOpen(t('popupcontext.msg'), t('statusCodes.11'), (<Button onClick={() => resendMail(data)}>{t('popupcontext.resend')}</Button>) ) 
        : handleError(e));
    } else if(authType === 'Email') {
      return request('user.verifyTOTP', eData)
      .then(async (res) => {
        if(res) {
          await setUser({ ...userInfo, token: userTkn });
          history.push({pathname: '/admin'}) 
        } else {
          popupMessageOpen(t('popupcontext.msg'), t('statusCodes.20')) 
        }
      }).catch(e => 
        e?.response?.data?.statusCode === 11 ? 
          popupMessageOpen(t('popupcontext.msg'), t('statusCodes.11'), (<Button onClick={() => resendMail(data)}>{t('popupcontext.resend')}</Button>) ) 
        : handleError(e));
    }
  };

  return(
    user.token && !userHasTwoFA(user) ?
    <Redirect to={'/profile/twoFA'} />  
    : user.token && userHasTwoFA(user) ?
    <Redirect to={'/admin'} /> 
    :
    <div className="log-reg-area sign">
      
      <h2 className="log-title">{t('login.title')}</h2>
      <h3 className="log-title">{t('login.Login')}</h3>
      {
        TwoFA?
          <Form
            onSubmit={onSubmitTwoFA}
            initialValues={{remember: getRememberMe()}}
            render={({ handleSubmit, submitting}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field component={TextField}
                    name="token"
                    label='2FA token'
                    required={required}
                    autoComplete="off"
                    maxLength="6"
                    onKeyPress={(event) => {
                      if(validateNumber(event.key)) {
                          event.preventDefault();
                      }
                    }}
                  />
                  <Link to="/forgot" className="forgot-pwd">{t('login.forgot')}? </Link>
                  <Field component={CheckBox}
                    name="remember"
                    label={t('fields.remember')}
                    type="checkbox"
                    onChange={e => setRememberMe(e.target.checked)}
                  />
                  <div className="submit-btns text-center">
                    <LoadingButton title='BACK' onClick={() => setTwoFA(false)} />
                    <LoadingButton title={t('login.LOGIN')} loading={submitting} tooltip="Click here to login to your account" />
                  </div>
                </form>
            )}}
          />
        :
        <Form
          onSubmit={onSubmit}
          initialValues={{remember: getRememberMe()}}
          render={({ handleSubmit, submitting}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field component={TextField}
                  name="Email"
                  label={t('fields.Username')}
                  type="email"
                  validate={required}
                  required
                />
                <Field component={TextField}
                  name="Password"
                  label={t('fields.Password')}
                  type="password"
                  validate={required}
                  required
                />
                <Link to="/forgot" className="forgot-pwd">{t('login.forgot')}? </Link>
                <Field component={CheckBox}
                  style={{ marginBottom: "0" }}
                  name="remember"
                  label={t('fields.remember')}
                  type="checkbox"
                  onChange={e => setRememberMe(e.target.checked)}
                />
                <div className="submit-btns text-center">
                  <LoadingButton title={t('login.LOGIN')} loading={submitting} tooltip="Click here to login to your account" />
                </div>
              </form>
          )}}
        />
      }
    </div>
  );
}