import React, { Fragment, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';
import { getImageUrl, getName, userIsAdmin } from '../../Util/common';
import { useGalleryContext } from '../../Contexts/galleryContext';
import Memobjects from '../Memobjects';
import { request } from '../../Services/networkService';
import { useTranslation } from "react-i18next";
import LoadingButton from '../../Components/LoadingButton';

export default function Header({asUser, userPage, memobjects=false, refreshPosts, refresh, setRefreshMemobjects}) {
  const { galleryOpen } = useGalleryContext();
  const { handleError, popupCropperOpen } = usePopupContext();
  const { user, setUser, setTargetUser, getObjectAvatar } = useUserContext();
  const {t} = useTranslation('common');
  const [loading, setLoading] = useState(false);

  const currentUser = asUser || user;
  const isOwner = asUser && (asUser.Owner === user._id || asUser.groupRelation === 'Moderator');
  const readOnly = !isOwner && !!asUser;
  const backgroundImageUrl = userPage ? userPage.BackgroundImage : currentUser.BackgroundImage;
  const profileImageUrl = userPage ? getObjectAvatar(userPage) : getObjectAvatar(currentUser);
  const backgroundImage = backgroundImageUrl ? getImageUrl(backgroundImageUrl) : `${process.env.PUBLIC_URL}/images/background.jpg`;

  const uploader = type => {

    const options = type === 'Profile' ? 
      {
        viewport: {width: 150, height: 150},
        size: {width: 800, height: 800}
      } : {
        viewport: {width: 400, height: 132},
        size: {width: 1200, height: 400}
      };

    popupCropperOpen(options)
      .then(file => {
        const api = userPage ? 'page' : (isOwner ? 'honoree' : 'user');
        const id = userPage ? userPage._id : currentUser._id;

        let formData = new FormData();
        formData.append('id', id);
        formData.append('file', file);

        return request(`${api}.upload${type}Pic`, formData)
          .then(res => userPage ? refresh() : (asUser ? setTargetUser({ ...asUser, ...res }) : setUser({ ...user, ...res })))
          .catch(handleError);
      }).catch(() => {});
  };

  const sendGroupRequest = () => {
    setLoading(true);
    request('group.request', {groupId: asUser.OwnGroup})
      .then(() => {
        refresh && refresh();
        refreshPosts && refreshPosts();
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  };

  return(
    <section>
      <div className="feature-photo">
        <div className="container-fluid">
          <div className="row merged">
             <div className="col-lg-10">
              <div className="timeline-info" data-tut="reactour__nameandlinks">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
