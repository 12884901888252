import React, { useRef, useEffect, useState } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { Field } from 'react-final-form';
import Select from '../../Components/Form/Select';
import { request, saveTrackingData } from '../../Services/networkService';
import { useTranslation } from "react-i18next";
import { getName } from '../../Util/common';
import { useUserContext } from '../../Contexts/userContext';

export default function Pages(){
  const {t} = useTranslation('common');
  const { handleError, popupFormOpen } = usePopupContext();
  const { user } = useUserContext();
  const tableRef = useRef();
  const [users, setUsers] = useState([]);

  useEffect(()=>{
    request('admin.users.list')
      .then(({data}) => setUsers(data.map(o => ({
        id: o._id,
        name: getName(o)
      }))))
      .catch(handleError);
  }, []);

  const columns = [{
    Header: t('admin.name'),
    accessor: 'Name'
  }, {
    Header: t('admin.description'),
    accessor: 'Description'
  }];

  const actionLink = (row) => {
    const userPopup = (
      <div className="form-row">
        <Field
          component={Select}
          name="userId"
          label={t('admin.selectUser')}
          options={users}
        />
      </div>
    );
    return popupFormOpen(t('admin.relinkUser'), userPopup, {})
      .then(data => request('admin.orphaned.relink', {
        type: 'page',
        id: row._id,
        userId: data.userId
      }))
      .then(() => tableRef.current.refresh())
      .catch(handleError);
  };

  const actions = [{
    iconcls: 'fa fa-link',
    onClick: actionLink,
    title: t('admin.relinkUser')
  }];

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/orphaned-pages')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-save-alt" /> {t('admin.orphanedPages')}</h5>

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.orphaned.pages'}
          disableFilters
          onError={handleError}
          columnRefreshTrigger={users.length}
        />

      </div>
    </div>
  );
}
