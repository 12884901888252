import React, { useRef, Fragment, useEffect } from 'react';
import ReactTable from '../../../Components/ReactTablePagination';
import { statusColumn } from '../../../Components/ReactTable/StatusColumn';
import { getImageUrl, formatDateTime, addLog } from '../../../Util/common';
import { usePopupContext } from '../../../Contexts/popupContext';
import { request, saveTrackingData } from '../../../Services/networkService';
import { useGalleryContext } from '../../../Contexts/galleryContext';
import { Button, Modal } from 'react-bootstrap';
import { reportOptions } from '../../../Util/constants';
import EmailDialog from '../ads_email_dialog';
import moment from 'moment';
import { useUserContext } from '../../../Contexts/userContext';

export default function Ads(){
  const { handleError, popupConfirmOpen, popupSubmit, popupClose, popupOpen, popupMessageOpen } = usePopupContext();
  const tableRef = useRef();
  const { galleryOpen } = useGalleryContext();
  const { user } = useUserContext();

  const columns = [{
    ...statusColumn((val) => {
      return !val.disabled && (!val.sale || (val.sale && (val.sale.infinite || moment(val.sale.expiry).isAfter(moment()))));
    }),
    accessor: ''
  },{
    Header: '',
    accessor: 'uploads',
    width: 60,
    Filter: () => {},
    Cell: ({value}) => {
      const imageUrl = getImageUrl(value[0]?.url);
      return (imageUrl ? <div onClick={() => galleryOpen(imageUrl)} className="grid-row-img" style={{backgroundImage: `url("${imageUrl}")`}}></div> : '')
    }
  },{
    Header: 'Text',
    accessor: 'text',
    Cell: ({value}) => value.length > 11 ? <div dangerouslySetInnerHTML={{__html: value}}></div> : <div>---</div>
  },{
    Header: 'Posted By',
    accessor: 'postedBy.Email'
  },{
    Header: 'Page',
    accessor: 'postedAs.Name'
  },{
    Header: "Created At",
    accessor: 'date',
    Cell: ({value}) => value ? formatDateTime(value) : ''
  },{
    Header: "Reported",
    accessor: 'reports',
    Cell: ({value}) => value.length ? 'Yes' : 'No',
    width: 100
  }];

  const actions = [{
    iconcls: 'fa fa-list-alt',
    onClick: (row, {viewIndex}) => popupDetailsOpen(row)
      .then(data => popupConfirmOpen('Compose email to ad owner', null, null, <EmailDialog post={data} />))
      .then(mailData => request('admin.sendMail', mailData)
          .then(r => popupMessageOpen('Success', 'Email was sent successfully'))
          .catch(handleError)
      )
      .catch(() => {}),
    title: "Report details",
    condition: row => row.reports?.length
  },{
    iconcls: 'fa fa-ban',
    onClick: (row, {viewIndex}) => request('admin.ads.update', {_id: row._id, disabled: true})
      .then(tableRef.current.refresh),
    title: "Disable ad",
    condition: row => row.disabled === false
  },{
    iconcls: 'fa fa-trash-o',
    onClick: row => popupConfirmOpen('Confirm', 'Are you sure you want to delete this ad?')
      .then(() => {
        request('admin.ads.delete', {id: row._id})
          .then(tableRef.current.refresh)
          .finally(() => saveLog(row, true))
      }),
    title: "Delete ad"
  }];

  const popupDetailsOpen = data => {
    const popupOptions = {
      title: 'Report Details',
      closeReject: false,
      content: (
        <Fragment>
          <Modal.Body className="text-center">
            <table className="popup-reported-ads-table">
              <tbody>
                {
                  data.reports.map((o, idx) => {
                    const reason = reportOptions.find(obj => obj.id === o.reasonId);
                    return (
                      <tr key={idx}>
                        <td>{formatDateTime(o.date)}</td>
                        <td>{reason.name}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => popupSubmit(data)}>Send Email</Button>
            <Button onClick={popupClose}>Ok</Button>
          </Modal.Footer>
        </Fragment>
      )
    }
    return popupOpen(popupOptions);
  }

  const saveLog = (data, deleted = false) => {
    let logData = {
      type: 'Ads',
      title: `Ad: ${data._id}`,
      message: `Ad '${data._id}' posted as '${data.postedAs.Name}' has been ${deleted ? 'deleted' : ''} by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
    };

    addLog(logData, user);
  }

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/ads')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-announcement" /> Ads</h5>

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.ads.list'}
          onError={handleError}
        />
      </div>
    </div>
  );
}
