export const USER_KEY = 'userData';

export const relations = [
  {name: 'Father', id: 'Father'},
  {name: 'Mother', id: 'Mother'},
  {name: 'Wife', id: 'Wife'},
  {name: 'Husband', id: 'Husband'},
  {name: 'Brother', id: 'Brother'},
  {name: 'Sister', id: 'Sister'},
  {name: 'Son', id: 'Son'},
  {name: 'Daughter', id: 'Daughter'},
  {name: 'Cousin', id: 'Cousin'},
  {name: 'Uncle', id: 'Uncle'},
  {name: 'Aunt', id: 'Aunt'},
  {name: 'Grand Parent', id: 'GrandParent'},
  {name: 'Grand Child', id: 'GrandChild'},
  {name: 'Friend', id: 'Friend'},
  {name: 'Son-in-law', id: 'Son-in-law'},
  {name: 'Daughter-in-law', id: 'Daughter-in-law'},
  {name: 'Other', id: 'Other'},
];

export const memobjectCategories = [
  {name: 'Balloons', id: 1},
  {name: 'Candles', id: 2},
  {name: 'Angels', id: 3},
  //{name: 'Coins', id: 4},
  {name: 'Flags', id: 5},
  {name: 'Flowers', id: 6},
  {name: 'Lanterns', id: 7},
  //{name: 'Mistletoe', id: 8},
  {name: 'Oil lamps', id: 9},
  {name: 'Memorial stones', id: 10},
  {name: 'Teddy bears', id: 11},
  //{name: 'Food', id: 12},
  //{name: 'Other', id: 13}
  {name: 'Wreath', id: 14},
  {name: 'Memorial boats', id: 15},
  {name: 'Pet Toys', id: 16},
  {name: 'Pet Treats', id: 17}
].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

export const backendErrors = {
  0: 'Incorrect password',
  1: 'Login failed',
  2: 'Access denied',
  3: 'Error uploading file',
  4: 'Uploaded file is not an image',
  5: 'Error uploading Memobject',
  6: 'Invalid item price',
  7: 'No customer ID',
  8: 'Uploaded file is not supported',
  9: 'User already registered in Memkeepers by other method',
  10: 'User does not exist',
  11: 'Please verify your Email address.'
};

export const reportOptions = [
  {id: 0, name: 'Irrelevant'},
  {id: 1, name: 'Misleading/Scam'},
  {id: 2, name: 'Inappropriate'},
  {id: 3, name: 'Offensive'},
  {id: 4, name: 'Sexually Indecent'},
  {id: 5, name: 'Other'}
];

export const recurringDailyEvents = [
  {id: 0, name: 'Every Sunday'},
  {id: 1, name: 'Every Monday'},
  {id: 2, name: 'Every Tuesday'},
  {id: 3, name: 'Every Wednesday'},
  {id: 4, name: 'Every Thursday'},
  {id: 5, name: 'Every Friday'},
  {id: 6, name: 'Every Saturday'},
  {id: 7, name: 'Every Month'},
  {id: 8, name: 'Every Year'}
]