import React from 'react';
import { Form, Field } from 'react-final-form';
import TextField from '../../../Components/Form/TextField';
import LoadingButon from '../../../Components/LoadingButton';

const Header = (props) => {
    const now = new Date();
    // return `${tempDate.getFullYear()}-${tempDate.getMonth() + 1}-${tempDate.getDay()}`;
    const month = (numOfMonths) => {
        let tempDate = now;

        switch (numOfMonths) {
            case 1:
                tempDate.setMonth(tempDate.getMonth() - 1);
                break;
            case 3:
                tempDate.setMonth(tempDate.getMonth() - 3);
                break;
            case 6:
                tempDate.setMonth(tempDate.getMonth() - 6);
                break;
            case 12:
                tempDate.setMonth(tempDate.getMonth() - 12);
                break;
            default:
                break;
        }

        return tempDate.toJSON().slice(0, 10);
    }

    const week = (numOfWeeks) => {
        let tempDate = now;

        switch (numOfWeeks) {
            case 1:
                tempDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6);
                break;
            case 2:
                tempDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 12);
                break;
            default:
                break;
        }

        return tempDate.toJSON().slice(0, 10);
    }

    const onSubmit = (data) => {
        // props.setFilter(data.target.value)
    }

    return (
        <div className='headerCnt'>
            <div className='head'>
                <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, pristine, form, values}) => {
                return (
                    <form onSubmit={e => handleSubmit(e, form)}>
                        <Field component={TextField} onChange={(data) => props.addFilter(data.target.value)}
                            name="enddate"
                            label="Search by date"
                            type="date"
                        />
                        <div className='form-row' style={{ justifyContent: 'center' }}>
                            <LoadingButon onClick={() => props.addFilter(week(1), true)} title="1 Week" />
                            <LoadingButon onClick={() => props.addFilter(week(2), true)} title="2 Weeks" />
                        </div>
                        <br/>
                        <div className='form-row' style={{ justifyContent: 'center' }}>
                            <LoadingButon onClick={() => props.addFilter(month(1), true)} title="1 Month" />
                            <LoadingButon onClick={() => props.addFilter(month(3), true)} title="3 Months" />
                        </div>
                        <br/>
                        <div className='form-row' style={{ justifyContent: 'center' }}>
                            <LoadingButon onClick={() => props.addFilter(month(6), true)} title="6 Months" />
                            <LoadingButon onClick={() => props.addFilter(month(12), true)} title="12 Months" />
                        </div>
                    </form>
                )}}
            />
            </div>
        </div>
    );
}

export default Header;
