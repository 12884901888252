import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../../Contexts/popupContext';

export default function FileUpload ({ input, label, meta, required, isInvalid, multiselect, ...others }) {
  const style = ((meta.error || meta.submitError) && meta.touched && !others.readOnly) ? { 'color': 'red' } : {};
  const [showPreview, setShowPreview] = useState(false);
  const [files, setFiles] = useState([]);
  const {t} = useTranslation('common');
  const { popupMessageOpen } = usePopupContext();
  const ref = useRef(null);

  const text = () => {
    if (files && files.length > 0) {
      if (files.length > 1) {
        return `${files.length} files selected`;
      }
      return files[0] && files[0].name;
    }
    return others.placeholder && others.placeholder.length ? others.placeholder : 'Select file';
  }

  const removeAttachment = (file) => {
    let currentFiles = files;
    let updatedFiles = currentFiles.filter(item => item !== file);
    
    setFiles(updatedFiles);
  }

  const nameFormat = (str) => {
    let mainStr = str;
    const formattedName = mainStr.slice(0, 8);
    const fileType = mainStr.slice(mainStr.indexOf('.'));

    return `${formattedName + fileType}`
  }

  const filesPreview = () => {
    return (
      files.map(file => 
        <div className='fbUploadContainer' key={file.lastModified + '_' + Math.floor(Math.random() * 10)}>
          <div className='form-file-upload fbUpload'>
            <span>{nameFormat(file.name)}</span>
            <i className='ti-trash' onClick={() => removeAttachment(file)}></i>
          </div>
          <div className='img' onClick={() => window.open(URL.createObjectURL(file), '_blank')}>
            <img loading='lazy' src={URL.createObjectURL(file)}></img>
          </div>
        </div>
      )
    )
  }

  useEffect(() => {
    input.onChange(files)
  }, [files]);

  return (
    <>
      <label className="file-input-label" style={style}>{label} {required &&
        <span className="is-required">*</span>}
      </label>
      <div className="file-input-wrapper">
        <div className="input-group">
          <div className="form-file-upload" onClick={() => ref.current.click()}>
            {text()}
          </div>
          <input
            ref={ref}
            onChange={e => {
              if(others.max && others.max !== 0 && multiselect) {
                const maxFilesNumber = others.max;
                if(e.target.files.length <= maxFilesNumber) {
                  const arr = Array.from(e.target.files);
                  setFiles(arr);
                  setShowPreview(true);
                  others.onLoad && others.onLoad(e);
                } else {
                  e.preventDefault();
                  popupMessageOpen(t('newpost.error'), `Cannot upload more than ${maxFilesNumber} files`);
                  return;
                }
              } else {
                const arr = Array.from(e.target.files);
                setFiles(arr);
                setShowPreview(true);
                others.onLoad && others.onLoad(e);
              }
            }}
            type="file"
            className="form-file-upload-hidden"
            multiple={multiselect ? true : false}
            {...others}
          />
        </div>
      </div>
      {
        (others.filespreview === 'true' || others.filespreview === true) && showPreview === true &&
        <div className='filesPreview'>
          {filesPreview()}
        </div>
      }
    </>
  );
}
