import React from 'react';
import ReactDOM from 'react-dom';
import "./Style/index.scss";
import './assets/css/main.min.css';
import './assets/css/style.css';
import './assets/css/color-blue.css';
import './assets/css/responsive.css';
import './assets/css/widgetBoard.css';
import './assets/css/usersMap.css';
import './assets/css/manager.css';
import './assets/css/logs.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_en from "./translations/en/common.json";
import common_bg from "./translations/bg/common.json";
import common_ru from "./translations/ru/common.json";
import common_it from "./translations/it/common.json";
import common_fr from "./translations/fr/common.json";
import common_de from "./translations/de/common.json";
import common_gr from "./translations/gr/common.json";
import common_es from "./translations/es/common.json";
import common_tr from "./translations/tr/common.json";
import common_jp from "./translations/jp/common.json";
import common_cn from "./translations/cn/common.json";
import common_pl from "./translations/pl/common.json";
import common_cz from "./translations/cz/common.json";
import common_hn from "./translations/hn/common.json";
import common_ro from "./translations/ro/common.json";
import common_po from "./translations/po/common.json";
import common_cr from "./translations/cr/common.json";
import common_nl from "./translations/nl/common.json";
import common_is from "./translations/is/common.json";
import common_ar from "./translations/ar/common.json";
import common_ko from "./translations/ko/common.json";

import quicklinks_en from "./translations/en/quicklinks.json";
import quicklinks_bg from "./translations/bg/quicklinks.json";
import quicklinks_ar from "./translations/ar/quicklinks.json";
import quicklinks_cn from "./translations/cn/quicklinks.json";
import quicklinks_cr from "./translations/cr/quicklinks.json";
import quicklinks_cz from "./translations/cz/quicklinks.json";
import quicklinks_nl from "./translations/nl/quicklinks.json";
import quicklinks_fr from "./translations/fr/quicklinks.json";
import quicklinks_de from "./translations/de/quicklinks.json";
import quicklinks_gr from "./translations/gr/quicklinks.json";
import quicklinks_hn from "./translations/hn/quicklinks.json";
import quicklinks_it from "./translations/it/quicklinks.json";
import quicklinks_jp from "./translations/jp/quicklinks.json";
import quicklinks_ko from "./translations/ko/quicklinks.json";
import quicklinks_pl from "./translations/pl/quicklinks.json";
import quicklinks_ro from "./translations/ro/quicklinks.json";
import quicklinks_tr from "./translations/tr/quicklinks.json";
import quicklinks_es from "./translations/es/quicklinks.json";
import quicklinks_po from "./translations/po/quicklinks.json";
import quicklinks_is from "./translations/is/quicklinks.json";
import quicklinks_ru from "./translations/ru/quicklinks.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'enus',
  resources: {
    en: { common: common_en, quicklinks: quicklinks_en },
    enus: { common: common_en, quicklinks: quicklinks_en },
    bg: { common: common_bg, quicklinks: quicklinks_bg },
    ru: { common: common_ru, quicklinks: quicklinks_ru },
    it: { common: common_it, quicklinks: quicklinks_it },
    fr: { common: common_fr, quicklinks: quicklinks_fr },
    de: { common: common_de, quicklinks: quicklinks_de },
    gr: { common: common_gr, quicklinks: quicklinks_gr },
    es: { common: common_es, quicklinks: quicklinks_es },
    tr: { common: common_tr, quicklinks: quicklinks_tr },
    jp: { common: common_jp, quicklinks: quicklinks_jp },
    cn: { common: common_cn, quicklinks: quicklinks_cn },
    pl: { common: common_pl, quicklinks: quicklinks_pl },
    cz: { common: common_cz, quicklinks: quicklinks_cz },
    hn: { common: common_hn, quicklinks: quicklinks_hn },
    ro: { common: common_ro, quicklinks: quicklinks_ro },
    po: { common: common_po, quicklinks: quicklinks_po },
    cr: { common: common_cr, quicklinks: quicklinks_cr },
    nl: { common: common_nl, quicklinks: quicklinks_nl },
    is: { common: common_is, quicklinks: quicklinks_is },
    ar: { common: common_ar, quicklinks: quicklinks_ar },
    ko: { common: common_ko, quicklinks: quicklinks_ko }
  }
});

const steps = [
  {
    selector: '.tour-timeline',
    content: 'Here you view all posts - public & from groups you are a member of. Posts in timeline are divided into two tabs - Honoree Timeline & Pets Timeline'
  },{
    selector: '.tour-honorees',
    content: 'Here you create an honoree profile for deceased loved ones or pets which you wish to commemorate'
  },{
    selector: '.tour-friends',
    content: 'Here you view & invite your friends to join your groups & pages'
  },{
    selector: '.tour-pages',
    content: 'Here you create business & celebrity pages to showcase products or services'
  },{
    selector: '.tour-groups',
    content: 'Here you can view all groups you have created or are a member of'
  },{
    selector: '.tour-requests',
    content: 'Here you can view the members of groups you have created/administer. From here you can accept/reject requests for group membership & assign users as Moderators to your groups. When you hover the mouse over a symbol a popup will show the corresponding function'
  },{
    selector: '.tour-calendar',
    content: 'Here you view & schedule events from all groups you are a member of'
  },{
    selector: '.tour-advertise',
    content: 'Here you can post advertisements showcasing products or services. Advertisements can be posted either in the timeline or into advertisement fields on the right sidebar'
  },{
    selector: '.tour-profile',
    content: 'Here you provide information about yourself'
  }
];

const tourClose = () => {
  const event = new Event('tourClose');
  document.dispatchEvent(event);
};

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
  ,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
