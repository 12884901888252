import React, { useEffect, useState } from 'react';
import UsersMap from '../../../Components/Widgets/Map';
import { request } from '../../../Services/networkService';

const MainStatistics = () => {
    const [userData, setUserData] = useState([]);
    const [locationData, setLocationData] = useState({});

    const getUsersLocation = () => {
        request('admin.userLocations')
        .then(res => {
            setUserData(res);
        })
    }

    const showUsersCount = (data) => {
        setLocationData(data)
    }

    useEffect(() => {
        getUsersLocation();
    }, []);

    return (
        <div>
            <h3>Statistics</h3>
            <div className='boardContainer'>
                {
                    Object.keys(locationData).length ?
                    <div className='locatedUsers'><p>Users in <b>{locationData.city}</b>, <b>{locationData.country}</b>: <b>{locationData.count}</b></p></div>
                    :
                    <div className='locatedUsers'><p>Users by City & Country</p></div>
                }
                <UsersMap userData={userData} showUsersCount={showUsersCount} />
            </div>
        </div>
    );
}

export default MainStatistics;
