import React from 'react';
import Manager from './manager/';

const Policies = () => {
    return (
        <div className="central-meta">
            <div className="editing-info">
                <h5 className="f-title"><i className="ti-layers-alt" /> Policies</h5>
                <Manager />
            </div>
        </div>
    );
}

export default Policies;
