import React from 'react';
import Manager from './manager';

const SEO_Posts = () => {
    return (
        <div className="central-meta">
            <div className="editing-info">
                <h5 className="f-title"><i className="ti-pencil-alt" /> SEO Posts</h5>
                <Manager />
            </div>
        </div>
    );
}

export default SEO_Posts;
