import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { usePopupContext } from '../../Contexts/popupContext';
import LoadingButton from '../../Components/LoadingButton';
import { useUserContext } from '../../Contexts/userContext';
import { request, useRequest } from '../../Services/networkService';
import { formatDateTime, getName } from '../../Util/common';
import Loading from '../../Components/Loading';
import { useTranslation } from "react-i18next";

export default function AllRequests(){
  const {t} = useTranslation('common');
  const history = useHistory();
  const { getAvatar } = useUserContext();
  const { handleError, popupMessageOpen } = usePopupContext();
  const {data, isLoading, handleRequest} = useRequest();

  const refresh = () => {
    handleRequest('group.getRequests');
  };

  useEffect(() => {
    refresh();
  }, []);

  const setUserStatus = (userId, groupId, status) => {
    request('group.setUserStatus', {userId, groupId, status})
      .then(() => {
        refresh();
        popupMessageOpen('', 'Changes were successfully saved');
      })
      .catch(handleError)
  };

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title">
          <i className="fa fa-users" /> 
          {t('requests.all')}
          <span className="pull-right">Selections are automatically saved and may be changed as needed.</span>
        </h5>

        {
            data && !!data.length &&
            <table className="users-table">
              <tbody>
                {data.filter(o => o.User).map(({User, ...o},index)=>(
                  <tr key={index}>
                    <td  onClick={() => history.push(`/userprofile/${User._id}`)}>
                      <div className="results-avatar" style={{backgroundImage: `url('${getAvatar(User.ProfileImage || null)}')`}} />
                    </td>
                    <td style={{fontSize: '12px'}}>
                      {formatDateTime(o.createdAt)}
                    </td>
                    <td style={{fontSize: '12px'}}>
                      {getName(User)} ({User.Email}) {t('requests.joinGroup')} {o.Group.Name}
                    </td>
                    <td>
                      {
                        <div style={{width: '140px'}}>
                          <LoadingButton
                            title={t('admin.moderator')}
                            className={`user-list-icon ${o.Status === 'Moderator' ? 'icon-active' : ''}`}
                            noClass
                            noTitle
                            iconCls="fa fa-star"
                            onClick={(e) => o.Status !== 'Moderator' && setUserStatus(User._id, o.Group._id, 'Moderator')}
                          />
                          <LoadingButton
                            title={t('requests.acceptReq')}
                            className={`user-list-icon ${o.Status === 'Accepted' ? 'icon-active' : ''}`}
                            noClass
                            noTitle
                            iconCls="fa fa-check"
                            onClick={(e) => o.Status !== 'Accepted' && setUserStatus(User._id, o.Group._id, 'Accepted')}
                          />
                          <LoadingButton
                            title={t('requests.denyReq')}
                            className={`user-list-icon ${o.Status === 'Denied' ? 'icon-active' : ''}`}
                            noClass
                            noTitle
                            iconCls="fa fa-times"
                            onClick={(e) => o.Status !== 'Denied' && setUserStatus(User._id, o.Group._id, 'Denied')}
                          />
                          <LoadingButton
                            title={t('requests.blackList')}
                            className={`user-list-icon ${o.Status === 'Blacklisted' ? 'icon-active' : ''}`}
                            noClass
                            noTitle
                            iconCls="fa fa-ban"
                            onClick={(e) => o.Status !== 'Blacklisted' && setUserStatus(User._id, o.Group._id, 'Blacklisted')}
                          />
                        </div>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }

          <Loading data={data} loading={isLoading} message={t('requests.noPendingReq')} />

      </div>
    </div>
  );
}
