import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';
import { request } from '../../Services/networkService';
import LoadingButton from '../../Components/LoadingButton';
import OutsideClick from '../../Components/OutsideClick';
// import Notifications from './notifications';
import Languages from './languages';
import Search from './search';
import Help from './help';
import { useTranslation } from "react-i18next";
import { userIsAdmin } from '../../Util/common';
import { Button } from 'react-bootstrap';
import { getRememberMe, setRememberMe } from '../../Util/storage';

import logo from '../../assets/images/logo.png';
import logoM from '../../assets/images/logo-m.png';

export default function TopBar() {
  const history = useHistory();
  const { handleError, popupMessageOpen } = usePopupContext();
  const { user, setUser, userLoggedIn, getAvatar, logout } = useUserContext();
  const {t} = useTranslation('common');

  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [remember, setRemember] = useState(getRememberMe());
  const [menuOpen, setMenuOpen] = useState(false);

  const resendMail = data => {
    return request('public.resendActivationMail', {Email: data.Email})
      .then(() => popupMessageOpen(t('popupcontext.msg'), t('statusCodes.12')))
      .catch(handleError)
  };

  const doLogin = e => {
    e.preventDefault();
    setRememberMe(remember);
    request('user.login', {Email, Password}).then((data) => {
      setUser({ ...data.user, token: data.token });
      history.push({
        pathname: '/timeline'
      }) 
    }).catch(e => 
      e?.response?.data?.statusCode === 11 ? 
        popupMessageOpen(t('popupcontext.msg'), t('statusCodes.11'), (<Button onClick={() => resendMail({Email})}>{t('popupcontext.resend')}</Button>) ) 
      : handleError(e));
  };

  return(
    <Fragment>
      
      <div className="responsive-header">
        <div className="mh-head first Sticky">
          <span className="mh-btns-left">
            <i className="fa fa-align-justify" onClick={() => setMenuOpen(!menuOpen)} />
          </span>
          <span className="mh-text">
            <Link to="/admin"><img src={logo} alt={t('topbar.mem')} title={t('topbar.mem-title')} /></Link>
          </span>
          {/* <span className="mh-btns-right">
            <Link to="/timeline" className="fa fa-home" style={{fontSize: "24px"}}></Link>
          </span> */}
        </div>
        <OutsideClick onOutsideClick={() => setMenuOpen(false)}>
          <nav id="menu" className="res-menu" style={{display: menuOpen ? 'block' : 'none'}}>
          {
            userLoggedIn() && 
            <ul>
              <li><Link to="/profile">{t('topbar.profile')}</Link></li>
              {userIsAdmin(user) && <li><Link to="/admin">{t('header.admin-panel')}</Link></li>}
              <li><Link to="/" onClick={logout}>{t('topbar.logout')}</Link></li> 
            </ul>
          }
          {
            !userLoggedIn() && 
            <ul>
              <li><Link to="/">{t('logout.loginLink')}</Link></li> 
            </ul>
          }   
          </nav>
        </OutsideClick>
      </div>

      <div className="topbar stick">
        <div className="container">
          <div className="logo">
            <Link to="/admin"><img src={logoM} alt={t('topbar.mem')} title={t('topbar.mem-title')} /></Link>
          </div>
          <div className="top-area">
            <ul className="main-menu">
              {/* { 
                userLoggedIn() && 
                <Fragment>
                  <li><Notifications /></li>
                </Fragment>
              } */}
              { 
                userLoggedIn() && 
                <li className="tour-profile">
                  <Link to="/profile/TwoFA" title="Here you provide information about yourself" className="user-img">
                    <div className="usericon user-avatar-image" style={{backgroundImage: `url("${getAvatar()}")`}} />
                  </Link>
                  <ul>
                    <li><Link to="/profile/TwoFA" title={t('topbar.profile')}><i className="ti-shield" /> Security</Link></li>
                    {userIsAdmin(user) && <li><Link to="/admin" title={t('header.admin-panel')}><i className="ti-panel" /> {t('header.admin-panel')}</Link></li>}
                    <li><Link to="/" title={t('topbar.log-out')} onClick={logout}><i className="ti-power-off" /> {t('topbar.logout')}</Link></li>
                  </ul>
                </li>
              }
            </ul>
          </div>
       
        </div>
    </div>
    </Fragment>
  );
}