import React from 'react';
import HeaderButton from './button';

const Header = ({page, setPage, setPageTitle}) => {

    const buttons = [
        {
            title: 'About Us',
            icon: 'ti-info-alt',
            id: 0
        },{
            title: 'Contact Us',
            icon: 'ti-email',
            id: 1
        },{
            title: 'Policies',
            icon: 'ti-files',
            id: 2
        },{
            title: 'How It Works',
            icon: 'ti-help',
            id: 3
        }
    ]

    return (
        <div className='managerHeader'>
            <div className='btnContainer'>
                {
                    buttons.map((button, i) => 
                        <HeaderButton key={i} title={button.title} icon={button.icon} active={page === button.id ? true : false} click={() => {
                            setPage(button.id);
                            setPageTitle(button.title);
                        }}/>
                    )
                }
            </div>
        </div>
    );
}

export default Header;
