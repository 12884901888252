import React, { useEffect, useState } from 'react';
import LoadingButon from '../../../../Components/LoadingButton';
import HeaderButton from './button';
import { Field } from 'react-final-form';
import TextField from '../../../../Components/Form/TextField';
import { usePopupContext } from '../../../../Contexts/popupContext';
import { request } from '../../../../Services/networkService';
import { Dropdown } from 'react-bootstrap';

const Header = ({page, pageTitle, setPage, setPageTitle}) => {
    const { handleError, popupMessageOpen, popupConfirmOpen, popupFormOpen } = usePopupContext();
    const [buttons, setButtons] = useState([])

    const listPages = () => {
        request('public.listBlogs')
        .then(res => setButtons(current => [...current, ...res]))
        .catch((err) => handleError(err))
    }

    const pageFormPopup = (editMode, o) => {

        return (
          <div className="form-row">
            <div className='form-group'>
              <Field component={TextField}
               name="title"
               type="text"
               placeholder="Page Name"
              />
            </div>
          </div>
        )
    };

    const newPage = () => {
        let body = {}
        popupFormOpen('Create Page', pageFormPopup())
        .then(result => {
          if(result) {
            body = {
                title: result.title,
                icon: 'ti-file',
                text: ''
            };
            request('admin.updateBlog', body)
            .then(res => {
                setButtons(current => [...current, res])
                popupMessageOpen('Message', 'The Page is successfully created')
            })
            .catch((err) => handleError(err))
          }
        })
    }

    const deletePage = (id) => {
        popupConfirmOpen('Delete confirmation', 'Are you sure you want to delete this page?')
        .then(result => {
            request('admin.deleteBlog', {id})
            .then(res => {
                setButtons(current => current.filter((item) => item.id !== id));
                setPage(0);
                setPageTitle('');
            })
            .catch((err) => handleError(err))
        })
    }

    useEffect(() => {
        listPages();
    }, [])

    return (
        <div className='managerHeader'>
            <div>
                <Dropdown>
                    <Dropdown.Toggle variant="info" id="dropdown-basic" size='lg'>
                        {
                            pageTitle ? pageTitle : 'Please select page'
                        }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            buttons.map((button, i) => 
                                <HeaderButton key={i} title={button.title} icon={button.icon} active={page === button.id} click={() => {
                                    setPage(button.id);
                                    setPageTitle(button.title);
                                }}/>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <br/>
            <div className="submit-btns text-center">
                {
                    page !== 0 && <LoadingButon onClick={() => deletePage(page)} iconCls='ti-trash' title="Delete Page" />
                }
                <LoadingButon onClick={newPage} iconCls='ti-plus' title="New Page" />
            </div>
        </div>
    );
}

export default Header;
