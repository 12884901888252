import React, { useState, useEffect } from 'react';
import Select from '../../Components/Form/Select';
import { Field } from 'react-final-form';
import { reportOptions } from '../../Util/constants';
import { getName } from '../../Util/common';
import { Form } from 'react-final-form';
import { Button, Modal } from 'react-bootstrap';
import { usePopupContext } from '../../Contexts/popupContext';

export default function AdsEmailDialog({post}){
  const [text, setText] = useState('');
  const [reportId, setReportId] = useState();
  const { popupSubmit, popupClose} = usePopupContext();

  useEffect(() => {
    const reason = reportOptions.find(o => o.id === reportId*1);
    const str = reason && `Hello ${getName(post.postedBy)},
This email is to notify you that an advertisement posted by you was reported as “${reason.name}”. Below are the report details:

For these reasons, MEMKEEPERS administration has removed the reported advertisement. 
Please refer to MEMKEEPERS policies before posting your next advertisement. If advertisements from your page continue to be reported, MEMKEEPERS will ban your account.

If you have any questions, you may contact us via email to support@memkeepers.me`;
    setText(str);
  }, [reportId, post.postedBy]);

  const result = {
    email: post.postedBy.Email,
    text: text && text.replace(/\n/g, '<br/>')
  };

  return (
    <Form
      onSubmit={() => {}}
      render={({ handleSubmit, submitting, values}) => {
        return (
          <form onSubmit={handleSubmit}>
           <Modal.Body>
              
              <div className="form-row">
                <Field
                  component={Select}
                  name="reasonId"
                  label="Choose email template"
                  options={reportOptions}
                  onChange={e => setReportId(e.target.value)}
                />
              </div>

              <div className="form-row">
                <textarea rows={8} value={text} onChange={e => {
                  setText(e.target.value);
                }} />
              </div>

              <div className="popup-form-btns">
                <Button type="button" onClick={() => popupSubmit(result)}>Send Email</Button>
                <Button type="button" onClick={popupClose}>Cancel</Button>
              </div>
            </Modal.Body>
          </form>
      )}}
    />

  );

};