import React, { useRef, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import { Form, Field } from 'react-final-form';
import FileField from '../../Components/Form/FileField';
import { required } from '../../Util/form';
import Croppie from "croppie";
import "croppie/croppie.css";

export default function Cropper (opts) {
  const { popupSubmit, popupClose } = usePopupContext();
  const {t} = useTranslation('common');
  const el = useRef(null);
  const reader = new FileReader();
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState('');

  reader.onload = o => {
    el.current.bind({
      url: o.target.result
    }).then(() => {
      //console.log('bind complete');
    });    
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]); 
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
          
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
      
    return new File([u8arr], filename, {type:mime});
  }

  useEffect(() => {
    const options = {
      enableExif: true,
      viewport: {
          width: 150,
          height: 150,
      },
      boundary: {
          width: 400,
          height: 300
      },
      ...opts
    };

    if (visible) {
      el.current = new Croppie(document.getElementById('cropper'), options);
    }
  }, [opts, visible]);

  const onLoadImage = e => {
    setVisible(true);
    reader.readAsDataURL(e.target.files[0]);
    setName(e.target.files[0].name);
  };

  const preventDefault = e => {
    e.stopPropagation(); 
    e.preventDefault();
  };

  const onFileDrop = e => {
    preventDefault(e);
    if (e.dataTransfer.files[0].type.split('/').shift() === 'image') {
      setVisible(true);
      reader.readAsDataURL(e.dataTransfer.files[0]);
      setName(e.dataTransfer.files[0].name);
    }
  }

  const onSubmitImage = () => {
    if (el.current) {
      el.current.result({
        type: 'canvas',
        size: opts.size || 'viewport',
        format: 'jpeg',
        quality: 0.7
      }).then(data => popupSubmit(dataURLtoFile(data, name)));
    }
  };

  return (        
    <Modal.Body>
       <Form
          onSubmit={onSubmitImage}
          render={({ handleSubmit, submitting, values, pristine, form}) => {
            return (
              <form onSubmit={e => handleSubmit(e, form)}>

                {visible && <div id="cropper" />}

                {!visible && 
                  <div className="upload-msg"
                    onDragOver={preventDefault}
                    onDragEnter={preventDefault}
                    onDrop={onFileDrop}>
                  {t('fields.cropper')}
                  </div>
                }

                <div className="col">
                  <Field component={FileField}
                   name="file"
                   type="file"
                   label={t('admin.selectFile')}
                   accept="image/*"
                   onLoad={onLoadImage}
                  />
                </div>

                <div className="popup-form-btns">
                  <Button type="submit">{t('popupcontext.k')}</Button>
                  <Button type="button" onClick={popupClose}>{t('popupcontext.cancel')}</Button>
                </div>

              </form>
          )}}
        />

    </Modal.Body>
  )
}