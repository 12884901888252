import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { formatDateTime } from '../../../Util/common';

const Content = (props) => {

    const isNumberEven = (number) => {
        if(number % 2 === 0) return true;
        else return false;
    }

    const elementIcon = (type) => {
        switch (type) {
            case 'Documents':
                return <i className='ti-layers-alt'/>
            case 'Products':
                return <i className='ti-package'/>
            case 'Promotions':
                return <i className='ti-ticket'/>
            case 'Users':
                return <i className='ti-id-badge'/>
            case 'Mementos':
                return <i className='ti-files'/>
            case 'Tokens':
                    return <i className='ti-gift'/>
            case 'Ads':
                return <i className='ti-announcement'/>
            default:
                break;
        }
    }

    return (
        <VerticalTimeline>
            {
                props.data && Object.keys(props.data).length ? 
                    props.data.map((data, i) => 
                    <VerticalTimelineElement
                        key={i}
                        className={isNumberEven(i) ? "vertical-timeline-element--work" : "vertical-timeline-element--education"}
                        date={formatDateTime(data.createdAt)}
                        iconStyle={isNumberEven(i) ? 
                            { background: 'rgb(33, 150, 243)', color: '#fff', textAlign: 'center', lineHeight: '60px', fontSize: '24px' } 
                            : 
                            { background: 'rgb(4,165,192)', color: '#fff', textAlign: 'center', lineHeight: '60px', fontSize: '24px' }}
                        icon={elementIcon(data.type)}
                    >
                        {/* <h3 className="vertical-timeline-element-title">{data.type}</h3> */}
                        <div className='tags'>
                            <div className='tag'>
                                <p>{data.type}</p>
                            </div>
                        </div>
                        <h4 className="vertical-timeline-element-subtitle">{data.title}</h4>
                        <p>
                        {data.message}
                        </p>
                    </VerticalTimelineElement>
                    )
                :
                <h2>Missing logs for this user or date</h2>
            }
        </VerticalTimeline>
    );
}

export default Content;
