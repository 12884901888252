import React, { useRef, useEffect } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { Field } from 'react-final-form';
import { required } from '../../Util/form';
import { useTranslation } from "react-i18next";
import TextField from '../../Components/Form/TextField';
import { request, saveTrackingData } from '../../Services/networkService';
import { addLog, formatCurrency } from '../../Util/common';
import { useUserContext } from '../../Contexts/userContext';

export default function Groups(){
  const {t} = useTranslation('common');
  const { handleError, popupFormOpen, popupConfirmOpen } = usePopupContext();
  const { user } = useUserContext();
  const tableRef = useRef();

  const columns = [{
    Header: t('admin.name'),
    accessor: 'name'
  },{
    Header: t('admin.description'),
    accessor: 'description'
  },{
    Header: 'Price',
    accessor: 'price',
    Cell: cell => cell.value ? formatCurrency(cell.value) : 'Free',
    width: 80
  },{
    Header: 'Autorenew',
    accessor: 'autorenew',
    Cell: cell => cell.value ? formatCurrency(cell.value) : 'Free',
    width: 110
  },{
    Header: 'Active Days',
    accessor: 'activeDays'
  }];

  const actions = [{
    iconcls: 'fa fa-pencil-square-o',
    onClick: (row, {viewIndex}) => popupFormOpen('Edit product', productFormPopup(true), {initialValues: row})
      .then(save)
      .then(() => saveLog(row))
      .then(res => tableRef.current.updateSingleRow(viewIndex, res)),
    title: 'Edit product'
  },{
    iconcls: 'fa fa-trash-o',
    onClick: row => popupConfirmOpen('Confirm', 'Are you sure you want to delete this product?')
      .then(() => {
        request('admin.products.delete', {id: row._id})
        .then(tableRef.current.refresh)
        .then(() => saveLog(row, true))
      }),
    title: 'Delete product',
    disabled: true
  }];

  const save = data => {
    const editMode = data._id;
    return request(`admin.products.${editMode ? 'update' : 'create'}`, data)
      .catch(handleError);
  };

  const saveLog = (data, deleted = false, created = false) => {
    let logData = {
      type: 'Products',
      title: `Product: ${data.name}`,
      message: `Product '${data.name}' has been ${deleted ? 'deleted' : created ? 'created' : 'updated'} by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
    };

    addLog(logData, user);
  }

  const productFormPopup = editMode => (
    <div className="form-row">

      <Field component={TextField}
       name="name"
       label={t('admin.name')}
       validate={required}
       required
       disabled={editMode}
      />

      <Field component={TextField}
       name="description"
       label={t('admin.description')}
      />

      <Field component={TextField}
       name="price"
       label="Price"
       validate={required}
       required
      />

      <Field component={TextField}
       name="autorenew"
       label="Autorenew Price"
      />

      <Field component={TextField}
       name="activeDays"
       label="Active Days"
       validate={required}
       required
      />

    </div>
  );

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/products')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-package" /> Products</h5>

        {/*<button onClick={() => request('admin.util')}>t('admin.utilBtn')</button>*/}

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.products.list'}
          disableFilters
          onError={handleError}
          openAddPopup={() => popupFormOpen('Create product', productFormPopup(false), {initialValues:{price: 0, activeDays: 5}})
            .then(save)
            .then((product) => saveLog(product, false, true))
            .then(tableRef.current.refresh)
          }
          defaultSorted={[{
            id: 'name',
            desc: true
          }]}
        />

      </div>
    </div>
  );
}
