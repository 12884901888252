import React from 'react';
import Layout from '../../Components/Layout';
import { useUserContext } from '../../Contexts/userContext';

import ChangePass from './ChangePass';
import Delete from './Delete';

import { useTranslation } from "react-i18next";
import TwoFactorAuth from './TwoFactorAuth';

export default function About() {

  const { user, setUser } = useUserContext();
  const {t} = useTranslation('common');

  const pageParams = {
    user: user,
    readOnly: false,
    update: setUser
  };

  const menuConfig = [
    {
      title: 'Two-Factor Auth',
      contents: TwoFactorAuth,
      iconCls: 'ti-lock',
      path: 'twoFA',
      params: pageParams
    },{
      title: t('profile.passChangeSidebar'),
      contents: ChangePass,
      iconCls: 'ti-lock',
      path: 'changePass',
      params: pageParams
    },{
      title: t('profile.deleteProfileSidebar'),
      contents: Delete,
      iconCls: 'ti-trash',
      path: 'deleteprofile'
    }
  ];

  return(
    <Layout config={menuConfig} title={t('profile.editInfoSidebar')} />
  );
};
