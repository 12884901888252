import React, { useRef, useEffect } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { Field } from 'react-final-form';
import { required } from '../../Util/form';
import Select from '../../Components/Form/Select';
import SelectAsync from '../../Components/Form/SelectAsync';
import { request, saveTrackingData } from '../../Services/networkService';
import debounce from 'debounce';
import { generateTextDropdown } from '../../Components/ReactTable/Filters';
import { useUserContext } from '../../Contexts/userContext';
import { addLog } from '../../Util/common';

export default function Groups(){
  const { handleError, popupFormOpen, popupConfirmOpen } = usePopupContext();
  const { user } = useUserContext();
  const tableRef = useRef();
  const { products } = useUserContext();

  const options = products ? products.filter(p => p.name !== 'Memento') : [];

  const columns = [{
    Header: 'Token',
    accessor: 'token',
    width: 330
  },{
    Header: 'Issued To',
    accessor: 'ownerName'
  },{
    Header: 'Email',
    accessor: 'ownerEmail'
  },{
    Header: 'Token Type',
    accessor: 'type',
    Cell: ({value}) => products ? products.find(t => t.id === value)?.name : '',
    Filter: generateTextDropdown(products, true),
    width: 150
  },{
    Header: 'Used',
    accessor: 'used',
    Cell: ({value}) => value === true ? 'Yes' : 'No',
    Filter: generateTextDropdown([{name: 'Yes', id: true}], true),
    width: 60
  }];

  const actions = [{
    iconcls: 'fa fa-trash-o',
    onClick: row => popupConfirmOpen('Confirm', 'Are you sure you want to delete this token?')
      .then(() => {
        request('admin.tokens.delete', {id: row._id})
        .then(saveLog(row, true))
        .then(tableRef.current.refresh)
      }),
    title: 'Delete token'
  }];

  const save = ({user, ...values}) => {
    const data = {...values, issuedFromName: 'MEMKEEPERS - Administration', owner: user.id, ownerName: user.name, ownerEmail: user.email};
    const editMode = data._id;
    return request(`admin.tokens.${editMode ? 'update' : 'create'}`, data)
      .then(data => saveLog(data))
      .catch(handleError);
  };

  const tokenFormPopup = editMode => (
    <div className="form-row">

      <Field component={Select}
       name="type"
       label="Token Type"
       validate={required}
       required
       options={options}
      />

      <Field component={SelectAsync}
       name="user"
       label="Issued To"
       validate={required}
       required
       isSearchable
       isMulti={false}
       loadOptions={debounce(loadUsers, 500)}
      />

    </div>
  );

  const loadUsers = (value, cb) => {
    request('common.search', {search: value, self: true})
      .then(data => cb(data.users.map((d) => ({ name: `${d.First_Name} ${d.Last_Name} - ${d.Email}`, id: d.id, email: d.Email }))))
      .catch(handleError);
  };

  const saveLog = (data, deleted = false) => {
    let logData = {
      type: 'Tokens',
      title: `Token: ${data.token}`,
      message: `Token '${data.token}' has been ${deleted ? 'deleted' : 'issued'} by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
    };

    addLog(logData, user);
  }

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/tokens')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-gift" /> Tokens</h5>

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.tokens.list'}
          onError={handleError}
          openAddPopup={() => popupFormOpen('Create token', tokenFormPopup(false))
            .then(save)
            .then(tableRef.current.refresh)
          }
          columnRefreshTrigger={products}
        />

      </div>
    </div>
  );
}
