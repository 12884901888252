import React from 'react';
import Manager from './manager/';

const Blog = () => {
    return (
        <div className="central-meta">
            <div className="editing-info">
                <h5 className="f-title"><i className="ti-pencil-alt" /> Blog</h5>
                <Manager />
            </div>
        </div>
    );
}

export default Blog;
