import React, { useState, useEffect } from 'react';
import BoardImages from '../../../Components/Widgets/BoardImages';
import BoardInfo from '../../../Components/Widgets/BoardInfo';
import { request } from '../../../Services/networkService';
// import { getImageUrl, getName } from '../../../Util/common';

const MemobjectStatistics = () => {
    const [memObjectCount, setMemObjectCount] = useState(0);
    const [usedMemos, setUsedMemos] = useState([]);

    useEffect(() => {
        request('memobjects.count')
        .then(res => {
            setMemObjectCount(res.result);
        })

        request('group.post.mostUsed')
        .then(res => {
            setUsedMemos(res)
        })
    }, [])

    return (
        <div>
            <h3>Statistics</h3>
            <div className='boardContainer'>
                <BoardInfo boardId={1} data={memObjectCount} title="Mementos" subText="Total number of memobjects"/>
                <BoardImages boardId={1} title="Top Mementos" subText="Top 10 Mementos Used" images={usedMemos}/>
            </div>
        </div>
    );
}

export default MemobjectStatistics;
