import React, { useRef, useEffect } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { useTranslation } from "react-i18next";
import { formatDateTime } from '../../Util/common';
import HonoreeStatistics from './honorees/statistics';
import { useUserContext } from '../../Contexts/userContext';
import { request, saveTrackingData } from '../../Services/networkService';
import { Field } from 'react-final-form';
import TextField from '../../Components/Form/TextField';

export default function Groups(){
  const {t} = useTranslation('common');
  const { handleError, popupFormOpen } = usePopupContext();
  const { user } = useUserContext();
  const tableRef = useRef();

  const columns = [{
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => value ? formatDateTime(value): '',
    width: 180
  },{
    Header: t('admin.name'),
    accessor: 'FirstName'
  },{
    Header: t('admin.type'),
    accessor: 'HonoreeType',
    Cell: ({value}) => value === 'Human' ? 'Person' : 'Pet'
  }];

  const ownerPopup = (
    <div className="form-row">
      <Field component={TextField}
       name="First_Name"
       label="Owner's name"
       readOnly
      />
      <Field component={TextField}
       name="Last_Name"
       label="Owner's last name"
       readOnly
      />
      <Field component={TextField}
       name='Email'
       label="Owner's email"
       readOnly
      />
    </div>
  );

  const actions = [
  {
    iconcls: 'fa fa-universal-access',
    onClick: row => findOwner(row.FirstName, row.Owner),
    title: "Show honoree owner",
  }
  ];

  const findOwner = (hName, uID) => {
    if(uID) {
      request('admin.users.get', { id: uID })
      .then(result => {
        if(result && Object.keys(result).length) {
          popupFormOpen(`${hName}'s Owner`, ownerPopup, {initialValues: result})
        }
        else alert('Owner not found')
      }) 
    } else {
      return alert('Owner not found')
    }
  }

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/honorees')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-agenda" /> {t('header.honorees')}</h5>
        <HonoreeStatistics />
        <br/>

        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.honorees.list'}
          disableFilters
          onError={handleError}
        />

      </div>
    </div>
  );
}
