import React, { useState, useEffect } from 'react';
import BoardInfo from '../../../Components/Widgets/BoardInfo';
import BoardMostUsers from '../../../Components/Widgets/BoardMostUsers';
import { request } from '../../../Services/networkService';

const UserStatistics = () => {
    const [userCount, setUserCount] = useState(0);
    const [lastMonth, setLastMonth] = useState(0);
    const [mostActiveFreeMementos, setMostActiveFreeMementos] = useState();
    const [mostActivePremiumMementos, setMostActivePremiumMementos] = useState();
    const [mostActivePosts, setMostActivePosts] = useState();
    const [mostActiveAdsPosts, setMostActiveAdsPosts] = useState();
    const [mostLogins, setMostLogins] = useState();

    const getUsersCount = (type) => {
        switch (type) {
            case 'month':
            request('user.count', {lastMonth: true})
            .then(res => {
                setLastMonth(res.result[0]?.lastMonth[0]?.count);
            })   
                break;
            case 'now':
            request('user.count')
            .then(res => {
                setUserCount(res.result);
            })
                break;
            default:
                break;
        }
    }

    const getMostActiveUsers = (dataType) => {
        request('group.post.mostPostedBy', {dataType})
        .then(res => {
            if(dataType === 'free mementos') {
                setMostActiveFreeMementos(res);
            } else if(dataType === 'premium mementos') {
                setMostActivePremiumMementos(res);
            } else if(dataType === 'ads') {
                setMostActiveAdsPosts(res);
            } else {
                setMostActivePosts(res);
            }
        })
    }

    const getMostLogins = () => {
        request('user.mostLogins')
        .then(res => {
            setMostLogins(res);
        })
    }

    useEffect(() => {
        getUsersCount('now');
        getUsersCount('month');
        getMostLogins();
        getMostActiveUsers('posts');
        getMostActiveUsers('free mementos');
        getMostActiveUsers('premium mementos');
        getMostActiveUsers('ads');
    }, [])

    return (
        <div>
            <h3>Statistics</h3>
            <div className='boardContainer'>
                <BoardInfo boardId={1} data={userCount} title="User Accounts" subText="Total number of user accounts"/>
                <BoardInfo boardId={2} data={lastMonth} title="Last Month" subText="Users joined last month"/>
                <BoardMostUsers boardId={1} title="Most Active Logins" subText="Most active logins" logins users={mostLogins}/>
                <BoardMostUsers boardId={2} title="Most Active Posts" subText="Most active users posted" posts users={mostActivePosts}/>
                <BoardMostUsers boardId={3} title="Most Active Free Mementos" subText="Most active free mementos posted" mementos users={mostActiveFreeMementos}/>
                <BoardMostUsers boardId={4} title="Most Active Premium Mementos" subText="Most active premium mementos posted" mementos users={mostActivePremiumMementos}/>
                <BoardMostUsers boardId={5} title="Most Active Ad Posts" subText="Most active advertisments posted" ads users={mostActiveAdsPosts}/>
            </div>
        </div>
    );
}

export default UserStatistics;
