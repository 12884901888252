import React, { useRef, useEffect } from 'react';
import { usePopupContext } from '../../../Contexts/popupContext';
import { request, saveTrackingData } from '../../../Services/networkService';
import { useUserContext } from '../../../Contexts/userContext';
import LoadingButton from '../../../Components/LoadingButton';
import ReactTablePagination from '../../../Components/ReactTablePagination';
import { addLog, formatDateTime } from '../../../Util/common';
import { promoStatusColumn } from '../../../Components/ReactTable/StatusColumn';
import FileField from '../../../Components/Form/FileField';
import { Field } from 'react-final-form';
import { useTranslation } from "react-i18next";

export default function FB_Posts() {
  const { user } = useUserContext();
  const { handleError, popupMessageOpen, popupConfirmOpen, popupFormOpen } = usePopupContext();
  const tableRef = useRef();

  const modules = {
    toolbar: [
      [{ 'font': [] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

      [{ 'align': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link']
    ],
  }

  const postFormPopup = (editMode, o) => {

    return (
      <div className="form-column">
        <div className='fbPost upload'>
          <Field component={FileField}
           name="file"
           type="file"
           accept="image/*,.pdf"
           multiselect
           max={10}
           filespreview='true'
          />
        </div>
      </div>
    )
  };

  const columns = [promoStatusColumn(), {
      Header: 'Posted By',
      accessor: 'postedBy',
      width: 200
  },{
      Header: 'Text',
      accessor: 'text',
      Cell: ({value}) => value.length > 11 ? <div dangerouslySetInnerHTML={{__html: value}}></div> : <div>---</div>
  },{
      Header: "Created At",
      accessor: 'createdAt',
      sort: 'ASC',
      Cell: ({value}) => value ? formatDateTime(value) : ''
  }];

  const actions = [{
    iconcls: 'ti-lock',
    onClick: (row) => request('admin.fbPosts.update', {_id: row._id, disabled: true})
      .then(tableRef.current.refresh),
    title: "Disable Post",
    condition: row => row.disabled === false
  },{
    iconcls: 'ti-unlock',
    onClick: (row) => request('admin.fbPosts.update', {_id: row._id, disabled: false})
      .then(tableRef.current.refresh),
    title: "Enable Post",
    condition: row => row.disabled === true
  },{
    iconcls: 'ti-pencil-alt',
    onClick: (row) => popupFormOpen('Edit post', postFormPopup(true, row), {initialValues: row, modules})
      .then(updatedPost => request('admin.fbPosts.update', {_id: updatedPost._id, ...updatedPost})
      .then(saveLog(row))
      .then(tableRef.current.refresh)),
    title: "Edit Post",
  },{
    iconcls: 'ti-trash',
    onClick: row => popupConfirmOpen('Confirm', 'Are you sure you want to delete this post?')
      .then(() => {
        request('admin.fbPosts.delete', {id: row._id})
          .then(saveLog(row, true))
          .then(tableRef.current.refresh)
      }),
    title: "Delete Post"
  }];

  const postBody = (data) => {
    let formData = new FormData();
    formData.append('text', data.text || '');
    data.file.forEach(file => 
      formData.append('file', file)  
    )

    return formData;
  }

  const createPost = () => {
    let body = {}
    popupFormOpen('Create post', postFormPopup(), {modules})
    .then(result => {
      if(result) {
        body = postBody(result);
        request('admin.fbPosts.create', body)
        .then(res => {
          tableRef.current.refresh();
          saveLog(body, false, true)
          popupMessageOpen('Message', 'The FB Post is successfully created')
        })
        .catch((err) => handleError(err))
      }
    })
  }


  const saveLog = (data, deleted = false, created = false) => {
    let logData = {};

    if(data._id) {
      logData = {
        type: 'Facebook Posts',
        title: `Post: ${data._id}`,
        message: `Post '${data._id}' has been ${deleted ? 'deleted' : created ? 'created' : 'updated'} by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
      };
    } else {
      logData = {
        type: 'Facebook Posts',
        title: `New Post Added`,
        message: `New post has been ${deleted ? 'deleted' : created ? 'created' : 'updated'} by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
      };
    }

    addLog(logData, user);
  }

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/fbPosts')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-bookmark-alt" /> FB Posts</h5>        
        <div className="submit-btns text-center">
          <LoadingButton onClick={() => createPost()} iconCls='ti-plus' title="New Post" />
        </div>

        <ReactTablePagination
            ref={tableRef}
            columns={columns}
            actions={actions}
            api={'public.listPosts'}
            apiParam={{admin: true}}
            onError={handleError}
            defaultSorted={[{
                id: 'createdAt',
                desc: true
            }]}
        />
      </div>
    </div>
  );
}
