import React, { useRef, useEffect } from 'react';
import { usePopupContext } from '../../../Contexts/popupContext';
import { request, saveTrackingData } from '../../../Services/networkService';
import { useUserContext } from '../../../Contexts/userContext';
import LoadingButton from '../../../Components/LoadingButton';
import ReactTablePagination from '../../../Components/ReactTablePagination';
import { addLog, formatDateTime } from '../../../Util/common';
import { promoStatusColumn } from '../../../Components/ReactTable/StatusColumn';
import { Field } from 'react-final-form';
import TextField from '../../../Components/Form/TextField';
import { required } from '../../../Util/form';
import Select from '../../../Components/Form/Select';
import CheckBox from '../../../Components/Form/CheckBox';

export default function Promotions(){
  const { user } = useUserContext();
  const { handleError, popupMessageOpen, popupConfirmOpen, popupFormOpen } = usePopupContext();
  const tableRef = useRef();
  const { products } = useUserContext();

  const options = products ? products.filter(p => p.name !== 'Memento') : [];

  const columns = [promoStatusColumn(), {
      Header: 'Name',
      accessor: 'name',
      width: 200
  },{
      Header: 'Message',
      accessor: 'message'
  },{
      Header: 'Issue After',
      accessor: 'promo.issueAfter',
      width: 120,
  },{
      Header: 'Condition',
      accessor: 'promo.condition',
      width: 100
  },{
      Header: 'Second Condition',
      accessor: 'promo.secondCondition',
  },{
      Header: "Created At",
      accessor: 'createdAt',
      sort: 'ASC',
      Cell: ({value}) => value ? formatDateTime(value) : ''
  }];

  const actions = [{
    iconcls: 'ti-lock',
    onClick: (row, {viewIndex}) => request('admin.promotions.update', {_id: row._id, disabled: true})
      .then(tableRef.current.refresh),
    title: "Disable promotion",
    condition: row => row.disabled === false
  },{
    iconcls: 'ti-unlock',
    onClick: (row, {viewIndex}) => request('admin.promotions.update', {_id: row._id, disabled: false})
      .then(tableRef.current.refresh),
    title: "Enable promotion",
    condition: row => row.disabled === true
  },{
    iconcls: 'ti-pencil-alt',
    onClick: (row, {viewIndex}) => popupFormOpen('Edit promotion', promotionFormPopup(true), {initialValues: row})
      .then(updatedPromo => request('admin.promotions.update', {_id: updatedPromo._id, ...updatedPromo})
      .then(saveLog(row))
      .then(tableRef.current.refresh)),
    title: "Edit promotion",
  },{
    iconcls: 'ti-trash',
    onClick: row => popupConfirmOpen('Confirm', 'Are you sure you want to delete this promotion?')
      .then(() => {
        request('admin.promotions.delete', {id: row._id})
          .then(saveLog(row, true))
          .then(tableRef.current.refresh)
      }),
    title: "Delete promotion"
  }];

  const promotionFormPopup = editMode => (
    <div className="form-row">

      <Field component={TextField}
       name="name"
       label="Title"
       validate={required}
       required
      />

      <Field component={TextField}
       name="message"
       label="Description"
       validate={required}
       required
      />

      <h3>Promotion Logic</h3>
      <Field component={editMode ? TextField : Select}
       name="promo.issueToken"
       label="Issue Token"
       validate={required}
       required
       options={options}
       disabled={editMode}
      />

      <Field component={TextField}
       name="promo.issueAfter"
       label="Issue After"
       validate={required}
       required
       type="number"
       min="0"
       onInput={(event) => 
        event.target.value = !!event.target.value && Math.abs(event.target.value) >= 0 ? Math.abs(event.target.value) : null
      }
      disabled={editMode}
      />

      <Field component={editMode ? TextField : Select}
       name="promo.condition"
       label="Condition"
       validate={required}
       required
       options={[{id: 0, name: 'memento'}, {id: 1, name: 'ad'}]}
       disabled={editMode}
      />

      <Field component={editMode ? TextField : Select}
       name="promo.secondCondition"
       label="Second Condition"
       validate={required}
       required
      //  options={[{id: 0, name: 'purchases'}, {id: 1, name: 'renewals'}]}
       options={[{id: 0, name: 'purchases'}]}
       disabled={editMode}
      />

      <Field component={CheckBox}
       name="template"
       label="Create this promotion as a template!"
       type="checkbox"
      />
    </div>
  );

  const promotionBody = (data, fromTemplate = false) => {
    let body = {};

    if(fromTemplate) {
      const tmpData = data[0];
      body = {
        name: tmpData.name,
        message: tmpData.message,
        template: false,
        promo: {
          issueToken: tmpData.promo.issueToken,
          issueAfter: tmpData.promo.issueAfter,
          condition: tmpData.promo.condition,
          secondCondition: tmpData.promo.secondCondition,
        },
        createdBy: {
          id: user._id,
          firstName: user.First_Name,
          lastName: user.Last_Name,
          email: user.Email
        }
      }
    } else {
      body = {
        name: data.name,
        message: data.message,
        template: data.template && data.template,
        promo: {
          issueToken: data.promo.issueToken,
          issueAfter: data.promo.issueAfter,
          condition: data.promo.condition === "0" ? 'memento' : 'ad',
          secondCondition: data.promo.secondCondition === "0" ? 'purchases' : 'renewals'
        },
        createdBy: {
          id: user._id,
          firstName: user.First_Name,
          lastName: user.Last_Name,
          email: user.Email
        }
      }
    }

    return body;
  }

  const createPromo = () => {
    let body = {}
    popupFormOpen('Create promotion', promotionFormPopup())
    .then(result => {
      if(result) {
        body = promotionBody(result);
  
        request('admin.promotions.create', body)
        .then(res => {
          tableRef.current.refresh();
          saveLog(body, false, true)
          popupMessageOpen('Message', 'Promotion is successfully created')
        })
        .catch((err) => handleError(err))
      }
    })
  }

  const createFromTemplate = async (promos, id) => {
    const availableTemplates = promos;
    let promo = {};
    promo = await availableTemplates.filter(item => item.id === id);

    let body = promotionBody(promo, true);
    
    request('admin.promotions.create', body)
    .then(res => {
      tableRef.current.refresh();
      saveLog(body, false, true)
      popupMessageOpen('Message', 'Promotion is successfully created')
    })
    .catch((err) => handleError(err))
  }

  const templatesFormPopup = (promos) => (
    <div className="form-row">
      <Field component={Select}
       name="name"
       label="Promotion"
       validate={required}
       required
       options={promos}
      />
    </div>
  );

  const templates = () => {
    request('public.promotions', { extraParam: { template: true } })
    .then(result => {
      if(result.data && Object.keys(result).length) {
        popupFormOpen('Promotion templates', templatesFormPopup(result.data))
        .then(template => createFromTemplate(result.data, template.name))
      }
    })
    .catch(err => handleError(err))
  }

  const saveLog = (data, deleted = false, created = false) => {
    let logData = {
      type: 'Promotions',
      title: `Promotion: ${data.name}`,
      message: `Promotion '${data.name}' has been ${deleted ? 'deleted' : created ? 'created' : 'updated'} by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
    };

    addLog(logData, user);
  }

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/promotions')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-ticket" /> Promotions</h5>        
        <div className="submit-btns text-center">
          <LoadingButton onClick={() => templates()} iconCls='ti-files' title="Templates" />
          <LoadingButton onClick={() => createPromo()} iconCls='ti-plus' title="New Promotion" />
        </div>

        <ReactTablePagination
            ref={tableRef}
            columns={columns}
            actions={actions}
            api={'public.promotions'}
            apiParam={{template: false}}
            onError={handleError}
            defaultSorted={[{
                id: 'createdAt',
                desc: true
            }]}
        />
      </div>
    </div>
  );
}
