import React from 'react';
import MemorialsPage from './pages/memorialsPage';
import PageContent from './pages/pageContent';

const Content = ({pageId, pageTitle, pageContent}) => {

    const getPage = (id) => {
        switch (id) {
            case 0:
                return <MemorialsPage />;
            default:
                break;
        }
    }

    return (
        <div className='contentContainer'>
            {
                pageId !== 0 ?
                <PageContent pageId={pageId} pageTitle={pageTitle} pageContent={pageContent} />
                :
                getPage(pageId)
            }
        </div>
    );
}

export default Content;
