import React, { useState, useEffect } from 'react';
import BoardInfo from '../../../Components/Widgets/BoardInfo';
import { request } from '../../../Services/networkService';

const HonoreeStatistics = () => {
    const [userCount, setUserCount] = useState(0);
    const [lastMonth, setLastMonth] = useState(0);

    const getUsersCount = (type) => {
        switch (type) {
            case 'month':
            request('user.count', {lastMonth: true})
            .then(res => {
                setLastMonth(res.result[0].lastMonth[0].count);
            })   
                break;
            case 'now':
            request('user.count')
            .then(res => {
                setUserCount(res.result);
            })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        // getUsersCount('now');
        // getUsersCount('month');
        request('honoree.count')
        .then(res => {
            setUserCount(res.result);
        })
    }, [])

    return (
        <div>
            <h3>Statistics</h3>
            <div className='boardContainer'>
                <BoardInfo boardId={1} data={userCount} title="Honorees" subText="Total number of honorees accounts"/>
            </div>
        </div>
    );
}

export default HonoreeStatistics;
