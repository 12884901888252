import React from 'react';
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const UsersMap = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCd59YZJN5Mi-sSE-FcyHLyEmvzEXyR-hQ&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
      defaultZoom={3}
      defaultCenter={{ lat: 36.050556, lng: -40.099883 }}
    >
    {
    props.userData && props.userData.map((item, i) => {
        return (
            <div key={i}>
                <Marker position={item.position} onClick={() => props.showUsersCount(item)} />
            </div>
        )
    })
    }
    </GoogleMap>
)

export default UsersMap ;
