import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import LoadingButton from '../../Components/LoadingButton';
import { useTranslation } from "react-i18next";

export default function Footer() {
  const {t} = useTranslation('common');
  const now = new Date().getFullYear();
  return(
    <Fragment>
      <footer id="site-footer">
        <div className="row">
        <div className="widget">
              <div className="foot-logo adminFooterLogo">
                <div className="logo">
                  {/* <Link to="/admin"><img src={logo} alt="Memkeepers" /></Link> */}
                  <Link to="/admin"><h2>{t('login.title')}</h2></Link>
                </div>
              </div>
            </div>
        </div>
      </footer>
      <div className="bottombar">
        <div className="row">
          <div className="col-md-12">
            <p className="copyright">© MEMKEEPERS {now}. All rights reserved.</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
