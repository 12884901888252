import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MasterProvider from './Contexts';
import PrivateRoute from './Components/PrivateRoute';
import ErrorBoundary from './Components/ErrorBoundary';
import './App.css';

import Login from './Pages/Login';
import Forgotpassword from './Pages/PasswordForgot';
import ResetPassword from './Pages/PasswordReset';
import Profile from './Pages/Profile';
import PageNotFound from './ProfileComponent/PageNotFound.component';
import Admin from './Pages/Admin';
import Requests from './Pages/Requests';
import Purchases from './Pages/Purchases';
import Groups from './Pages/Groups';
import Pages from './Pages/Pages';
import VerifyUser from './Pages/Login/verify';
import AuthGoogle from './Pages/Login/authGoogle';
import Logs from './Pages/Admin/logs';

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <ErrorBoundary>
            <MasterProvider> 
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/forgot" exact component={Forgotpassword} />
                <Route path="/reset/:id" exact component={ResetPassword} />
                {/* <Route path="/contact" exact component={Contact} /> */}
                {/* <Route path="/howitworks" exact component={HowItWorks} /> */}
                {/* <Route path="/aboutmemkeepers" exact component={MemkeepersAbout} /> */}
                {/* <Route path="/policies" component={Policies} /> */}
                <Route path="/auth/google" component={AuthGoogle} />
                <Route path="/verify" component={VerifyUser} />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/admin" admin component={Admin} />
                <PrivateRoute path="/logs/:id" admin component={Logs} />
                <PrivateRoute path="/requests" component={Requests} />
                <PrivateRoute path="/purchases" component={Purchases} />
                <PrivateRoute path="/groups" component={Groups} />
                <PrivateRoute path="/pages" component={Pages} />
                <Route component={PageNotFound} />
              </Switch>
            </MasterProvider>
          </ErrorBoundary>
        </Router>
      </div>
    );
  }
}
