import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import TextField from '../../../../../Components/Form/TextField';
import { required } from '../../../../../Util/form';
import { useTranslation } from "react-i18next";
import { usePopupContext } from '../../../../../Contexts/popupContext';
import LoadingButton from '../../../../../Components/LoadingButton';
import { request } from '../../../../../Services/networkService';
import { addLog } from '../../../../../Util/common';
import { useUserContext } from '../../../../../Contexts/userContext';

export default function ContactUs() {
  const { handleError, popupMessageOpen } = usePopupContext();
  const { t } = useTranslation('common');
  const { user } = useUserContext();
  const [data, setData] = useState({})

  const onSubmit = data => {
    let logData = {
      type: 'Documents',
      title: 'Section: Contact Us',
      message: `There are new changes on the 'Contact Us' page updated by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
    };

    return request('admin.updateContactUs', data)
      .then(r => {
        addLog(logData, user);
        popupMessageOpen('Message', 'Data updated successfully');
      })
      .catch((e) => handleError(e));
  };

  useEffect(() => {
    request('public.listContactUs')
    .then(result => {
        if(result && result.length) setData(result[0]);
    })
    .catch((e) => handleError(e));
  }, [])

  return (
    <div className="contentContainer central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-email" /> Contact Us</h5>

        <Form
          onSubmit={onSubmit}
          initialValues={{ ...data }}
          render={({ handleSubmit, submitting, values, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name='phone'
                      className='managerInputs'
                      placeholder='Memkeepers Phone'
                      validate={required}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="email"
                      className='managerInputs'
                      placeholder='Memkeepers Email'
                      type="email"
                      validate={required}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="url"
                      className='managerInputs'
                      placeholder='Memkeepers URL'
                      validate={required}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="fUrl"
                      className='managerInputs'
                      placeholder='Facebook URL'
                      validate={required}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="col">
                    <Field component={TextField}
                      name="iUrl"
                      className='managerInputs'
                      placeholder='Instagram URL'
                      validate={required}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="tUrl"
                      className='managerInputs'
                      placeholder='Twitter URL'
                      validate={required}
                    />
                  </div>
                  <div className="col">
                    <Field component={TextField}
                      name="lUrl"
                      className='managerInputs'
                      placeholder='LinkedIn URL'
                      validate={required}
                    />
                  </div>
                </div>
                <br/>

                <div className="submit-btns text-center">
                    <LoadingButton iconCls="fa fa-save" loading={submitting} disabled={pristine} title={t('profile.updateBtn')} />
                </div>

              </form>
            )
          }}
        />
      </div>
    </div>
  );
}