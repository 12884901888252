import React, { Fragment } from 'react';
import TopBar from '../../Components/TopBar';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import SideBarLeft from '../../Components/SideBarLeft';
import SideBarRight from '../../Components/SideBarRight';
import FeedLeft from '../../Components/FeedLeft';
//import WidgetPanel from '../../Components/WidgetPanel';
import { useUserContext } from '../../Contexts/userContext';
//import Adsense from '../../Components/Adsense';

export default function Layout({showHeader = true, children, infoPage, config, title, tooltip, extraPanels, ...headerProps}) {
  const { userLoggedIn } = useUserContext();
  const header = showHeader && userLoggedIn();

  return(
    <Fragment>
      <TopBar/>

      <div className="feed-left">
        { config && <FeedLeft config={config} title={title} tooltip={tooltip} extraPanels={extraPanels}/>}
      </div>

      <div className="theme-layout marble">
        <div className={`container ${infoPage ? "gray-bg" : ""} adminPanel`}>
          { header && <Header {...headerProps} /> }
          <div className={`container-fluid ${!infoPage ? "gray-bg" : ""}`}>
            <div className="row">
              <div className="col-lg-12">
                <div className={`row ${header ? "" : "page-contents"}`} id="page-contents">
                  { config ? <SideBarLeft config={config} title="title" /> : children }
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>

    </Fragment>
  );
};