import React, { Fragment, useEffect, useState } from 'react';
import countries from './countriesList';
import { afrika_countries, asia_countries, european_countries, north_america_countries, oceania_countries, south_america_countries } from '../../../../Util/common';

export default function CountriesDropdown({ setPostCity, setPostCountry, setRegion }) {
  const [columns, setColumns] = useState([]);
  const [selectedCountry, setCountry] = useState('')
  const [selectedCity, setCity] = useState('')

  const contitentAndCountryCodes = (country) => {
    let AF = Object.values(afrika_countries).find(item => item.name.toLowerCase() === country.toString().toLowerCase())
    let AS = Object.values(asia_countries).find(item => item.name.toLowerCase() === country.toString().toLowerCase())
    let EU = Object.values(european_countries).find(item => item.name.toLowerCase() === country.toString().toLowerCase())
    let NA = Object.values(north_america_countries).find(item => item.name.toLowerCase() === country.toString().toLowerCase())
    let OC = Object.values(oceania_countries).find(item => item.name.toLowerCase() === country.toString().toLowerCase())
    let SA = Object.values(south_america_countries).find(item => item.name.toLowerCase() === country.toString().toLowerCase())

    if(AF && Object.keys(AF).length) return `AF-${AF.id}`
    else if(AS && Object.keys(AS).length) return `AS-${AS.id}`
    else if(EU && Object.keys(EU).length) return `EU-${EU.id}`
    else if(NA && Object.keys(NA).length) return `NA-${NA.id}`
    else if(OC && Object.keys(OC).length) return `OC-${OC.id}`
    else if(SA && Object.keys(SA).length) return `SA-${SA.id}`
  }

  const doSearch = (searched) => {
    let countriesCopy = countries;

    if(searched && searched.length) {
      if(selectedCountry && selectedCountry.length && !selectedCity) {
        let resultCities = Object.values(countriesCopy[selectedCountry]).filter(item => item.toLowerCase().includes(searched.toLowerCase()))

        if(resultCities && resultCities.length) sortcountries(resultCities)
        else sortcountries(countriesCopy[selectedCountry])
      } else {
        let resultCountries = Object.keys(countriesCopy).filter(item => item.toLowerCase().includes(searched.toLowerCase()));
        if (resultCountries && resultCountries.length) {
          sortcountries(resultCountries);
        } else sortcountries({});
      }
    } else if(selectedCountry && selectedCountry.length && !selectedCity) {
      sortcountries(countriesCopy[selectedCountry])
    } else sortcountries({});
  }

  const searchBox = () => {
    return (
      <form key="searchForm" >
        <input
          key="searchInput"
          type="text" 
          placeholder="Search by country/state/city"
          onChange={e => {
            doSearch(e.target.value);
          }} 
        />
      </form>
    )
  }

  const sortcountries = (finded, currentCountry) => {
    let fromSearch = finded && Object.keys(finded).length ? true : false;

    if(currentCountry && currentCountry.length) {
      if(fromSearch) {
        setColumns(finded)
      } else {
        setColumns(Object.values(countries[currentCountry]))
      }
    } else {
      if(fromSearch) {
        setColumns(finded)
      } else {
        setColumns(Object.keys(countries))
      }
    }
  }

  useEffect(() => {
    if(selectedCountry.length && selectedCity.length) setRegion(contitentAndCountryCodes(selectedCountry))
  }, [selectedCountry && selectedCity])

  useEffect(() => {
    sortcountries();
  }, [])

  return (
    <Fragment>
      <ul className="countries-dropdown">
        { searchBox() }
        { selectedCountry && <li className='selected'><span title={selectedCountry}>{selectedCountry}</span> { selectedCity && <span title={selectedCity}>- {selectedCity}</span> }</li> }
        <div className='result'>
        {
          columns.map((l, idx) => (
            <li key={idx}>
              {
                <span title={l} onClick={() => { 
                  if(selectedCountry.length && selectedCity) {
                    sortcountries('', l)
                    
                    setCity('')
                    setCountry(l)
                    setPostCity('')
                    setPostCountry(l)
                  } else if(!selectedCountry){
                    sortcountries('', l)

                    setCity('')
                    setCountry(l)
                    setPostCity('')
                    setPostCountry(l)
                  } else if(!selectedCity) {
                    sortcountries()

                    setCity(l)
                    setPostCity(l)
                  }
                 }}>
                  {l}
                </span>
              }
            </li>
          ))
        }
        </div>
      </ul>
    </Fragment>
  )
};