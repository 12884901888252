import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../Contexts/userContext';
import { Form, Field } from 'react-final-form';
import ToggleField from '../../Components/Form/Toggle';
import { usePopupContext } from '../../Contexts/popupContext';
import { request } from '../../Services/networkService';
import { useTranslation } from "react-i18next";
import TextField from '../../Components/Form/TextField';
import { required, validateNumber} from '../../Util/form';

export default function TwoFactorAuth(){
  const {t} = useTranslation('common');
  const { user, setUser } = useUserContext();
  const { handleError, popupTwoFAOpen } = usePopupContext();
  const [gAuth, setGAuth] = useState(false);
  const [emailAuth, setEmailAuth] = useState(false);

  const verifyFormOpen = () => (
    <div className="form-row">
      <Field component={TextField}
       name="token"
       label='Received token'
       required={required}
       autoComplete="off"
       maxLength="6"
       onKeyPress={(event) => {
        if(validateNumber(event.key)) {
            event.preventDefault();
        }
       }}
      />
    </div>
  );

  const generateQR = (data) => {
    // Make generateQR request to the server so we can get a QR code
    return request('user.generateQR', data.Email)
    .then(res => verifyGAuthAndUpdate(res, data))
    .catch(handleError);
  }

  const generateTOTPAndSend = (data, firstTime = true) => {
    return request('user.generateTOTP', {id: data._id})
    .then(res => {
        return request('user.sendTOTP', {id: data._id, fName: data.First_Name, lName: data.Last_Name, email: data.Email, secret: res.eToken, firstTime: firstTime})
        .then(sendedTOTP => verifyEmail(sendedTOTP, data))
        .catch(handleError)
    })
    .catch(handleError);
  }

  const deactivateAppAuth = (data) => {
    let body = { gToken: data.TwoFA.gToken, token: '' };
    popupTwoFAOpen('Verify the 2FA code', data, verifyFormOpen(), 'delete')
    .then(res => {
        body.token = res.token;
        return request('user.verifyGAuth', body)
        .then(response => {
            if(response) {
                // Remove gAuth token from the data
                data.TwoFA.gToken = '';
                data.TwoFA.GoogleAuthenticator = false;
                // Make update request to change the data in the DB
                return request('user.update', data)
                .then(res => setUser({ ...res, token: data.token }))
                .catch(handleError);
            } else {
              handleError('Wrong code!');
            }
        })
        .catch(handleError);
    })
    .catch(() => {});
  }

  const deactivateEmailAuth = (data) => {
    generateTOTPAndSend(data, false);
    let body = { eToken: data.TwoFA.eToken, otp: '' };
    popupTwoFAOpen('Verify the code', data, verifyFormOpen())
    .then(res => {
        body.otp = res.token;
        return request('user.verifyTOTP', body)
        .then(response => {
            if(response) {
              data.TwoFA.eToken = '';
              data.TwoFA.Email = false;
              // Make update request to change the data in the DB
              return request('user.update', data)
              .then(res => setUser({ ...res, token: data.token }))
              .catch(handleError);
            } else {
              handleError('Wrong code!');
            }
        })
        .catch(handleError);
    })
    .catch(() => {});
  }

  const onSubmit = data => {
    const userTwoFA = user.TwoFA;
    let dataTwoFA = data.TwoFA;

    if(userTwoFA.GoogleAuthenticator !== true && dataTwoFA.GoogleAuthenticator) {
        // Activate GoogleAuth as a 2FA method
        generateQR(data);
    }
    if(userTwoFA.Email !== true && dataTwoFA.Email) {
        // Activate Email as a 2FA method
        generateTOTPAndSend(data);
    }
    if(userTwoFA.GoogleAuthenticator && dataTwoFA.GoogleAuthenticator === false) {
        // Remove GoogleAuth as a 2FA method
        deactivateAppAuth(data);
    }
    if(userTwoFA.Email && dataTwoFA.Email === false) {
        // Remove Email as a 2FA method
        deactivateEmailAuth(data);
    }
  };

  const verifyGAuthAndUpdate = (data, userData) => {
    let body = { gToken: data.token, token: '' };
    userData.TwoFA.gToken = body.gToken;
    popupTwoFAOpen('Scan the code', data, verifyFormOpen(), 'google')
    .then(res => {
        body.token = res.token;
        return request('user.verifyGAuth', body)
        .then(response => {
            if(response) {
                return request('user.update', userData)
                .then(updatedUser => setUser({ ...updatedUser, token: userData.token }))
                .catch(handleError);
            } else {
              handleError('Wrong code!');
            }
        })
        .catch(handleError);
    })
    .catch(() => {});
  }

  const verifyEmail = (data, userData) => {
    let body = { eToken: data.eToken, otp: '' };
    userData.TwoFA.eToken = body.eToken;
    popupTwoFAOpen('Verify the code', data, verifyFormOpen())
    .then(res => {
        body.otp = res.token;
        return request('user.verifyTOTP', body)
        .then(response => {
            if(response) {
                return request('user.update', userData)
                .then(updatedUser => setUser({ ...updatedUser, token: userData.token }))
                .catch(handleError);
            } else {
              handleError('Wrong code!');
            }
        })
        .catch(handleError);
    })
    .catch(() => {});
  }

  useEffect(() => {
    if(user.TwoFA.GoogleAuthenticator) setGAuth(true);
    if(user.TwoFA.Email) setEmailAuth(true);
    return () => {
        setGAuth(false);
        setEmailAuth(false);
    };
  }, [user]);

  return (
    <div className="central-meta">
      <div className="onoff-options">
        <h5 className="f-title"><i className="ti-settings" /> Two-Factor Authentication(2FA)</h5>
        {t('profile.selectSetting')}

        <p>&nbsp;</p>

          <Form
            onSubmit={onSubmit}
            initialValues={{...user}}
            render={({ handleSubmit, submitError, submitErrors, submitting, values, pristine}) => {
              return (
                <form onSubmit={handleSubmit}>
                    <Field component={ToggleField}
                        id="TwoFA.GoogleAuthenticator"
                        name="TwoFA.GoogleAuthenticator"
                        label='Authenticator App'
                        text={t('profile.2FAGoogle')}
                        type="checkbox"
                        // onChange={(e) => setGAuth(e.target.checked)}
                        // checked={gAuth}
                    />

                    <Field component={ToggleField}
                        id="TwoFA.Email"
                        name="TwoFA.Email"
                        label='Email'
                        text={t('profile.2FAEmail')}
                        type="checkbox"
                        // onChange={(e) => setEmailAuth(e.target.checked)}
                        // checked={emailAuth}
                    />

                    <div className="submit-btns text-center">
                        <button disabled={pristine || submitting} type="submit" className="mtr-btn"><span>{t('profile.updateBtn')}</span></button>
                    </div>
                </form>
            )}}
          />
      </div>
    </div>
  );
}
