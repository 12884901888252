import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form, Field } from 'react-final-form';
import LoadingButon from '../../../../../Components/LoadingButton';
import ToggleField from '../../../../../Components/Form/Toggle';
import { useUserContext } from '../../../../../Contexts/userContext';
import { usePopupContext } from '../../../../../Contexts/popupContext';
import { request } from '../../../../../Services/networkService';
import { addLog } from '../../../../../Util/common';
import TextField from '../../../../../Components/Form/TextField';
import { required } from '../../../../../Util/form';

const policies = [
    {
        id: 0,
        policy: 'Privacy Policy',
    }, {
        id: 1,
        policy: 'Cookies Policy',
    }, {
        id: 2,
        policy: 'Terms and Conditions',
    }, {
        id: 3,
        policy: 'Disclaimer',
    }, {
        id: 4,
        policy: 'Refund Policy',
    }
]

const Policies = () => {
    const [selectedPolicy, setPolicy] = useState('');
    const [policyLink, setPolicyLink] = useState('');
    const [dataText, setDataText] = useState('');
    const { handleError, popupMessageOpen } = usePopupContext();
    const { user } = useUserContext();

    const modules = {
        toolbar: [
          [{ 'font': [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    
          [{ 'align': [] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ],
    }

    const listPolicy = (policy) => {
        request('public.listPolicy', {type: policy})
        .then(result => {
            setDataText(result.text);
            if(result.policyLink) setPolicyLink(result.policyLink);
            else setPolicyLink('');
        })
        .catch((err) => handleError(err));
    }

    const changePolicy = (policy) => {
        setPolicy(policy);
        listPolicy(policy)
    }

    const onSubmit = ({policyLink, confirm}) => {
        let body = {};
        let logData = {
            type: 'Documents',
            title: 'Section: Policies',
            message: `There are new changes on the '${selectedPolicy}' page updated by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
        };

        body = {
            updatedBy: {
                Id: user._id,
                First_Name: user.First_Name,
                Last_Name: user.Last_Name,
                Email: user.Email
            },
            text: dataText,
            policyLink: policyLink,
            type: selectedPolicy,
        }

        if(selectedPolicy.length && dataText && confirm && user) {
            submit();
        } else if(selectedPolicy.length && policyLink && confirm && user) {
            submit();
        } else {
            handleError();
        }


        function submit() {
            request('admin.updatePolicy', body)
            .then(result => {
                addLog(logData, user);
                popupMessageOpen('Message', 'Data updated successfully');
            })
            .catch((e) => handleError(e))
        }
    }

    return (
        <div className="contentContainer central-meta">
            <div className="editing-info">
                <h5 className="f-title"><i className="ti-files" /> Policies</h5>
                <div className='pull-left selectPageCont' name='policyType'>
                    <label><b>To update a policy, first select one</b></label><br/>
                    
                    <select
                    onChange={event => changePolicy(event.target.value)}
                    value={selectedPolicy}
                    className='advertising selectPage'
                    >
                    <option>---</option> 
                    {policies && policies.map(({policy, id}) => <option key={id} value={policy}>{policy}</option>)}
                    </select>
                </div>

                <br/>
                <br/>
                {
                    selectedPolicy.length && selectedPolicy!== '---' ?
                    <Form 
                        onSubmit={onSubmit}
                        initialValues={{ ...dataText, policyLink }}
                        render={({ handleSubmit, submitting, values, pristine }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    {
                                        selectedPolicy === 'Disclaimer' || selectedPolicy === 'Refund Policy' ?
                                        <ReactQuill 
                                            name="aboutUs"
                                            theme="snow"
                                            placeholder='The policy will be presented on Memkeepers'
                                            value={dataText}
                                            onChange={e=> setDataText(e)}
                                            modules={modules}
                                            className="documentsEditor"
                                        />
                                        :
                                        <div className='form-row'>
                                            <Field component={TextField}
                                                id="policyLink"
                                                name="policyLink"
                                                label={selectedPolicy + ' link'}
                                                onChange={(e) => setPolicyLink(e.target.value)}
                                                validate={required}
                                                required
                                            />
                                        </div>
                                    }

                                    <div className='onoff-options' style={{ marginBottom: "20px" }}>
                                    <Field component={ToggleField}
                                        id="confirm"
                                        name="confirm"
                                        label="Data update confirmation"
                                        text='After the data is updated the previos one will be overwritten!'
                                        type="checkbox"
                                    />
                                    </div>
                                    <div className="submit-btns text-center">
                                        <LoadingButon iconCls="fa fa-save" loading={submitting} disabled={pristine} title="Update"/>
                                    </div>
                                </form>
                            )
                        }}
                    />
                    :null
                }
            </div>
        </div>
    );
}

export default Policies;
