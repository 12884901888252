import React, { useEffect } from 'react';
import { animateNumber } from '../../Util/common';

const BoardInfo = ({ boardId = 0, data, title, subText, chartData, chart = false }) => {

    function toggleChart() {
        if(chart) {
            const element = document.getElementsByClassName('chart')[0];
            const elementHeight = element.style.height;
            const toggleIcon = document.getElementById('toggleIcon');

            if(elementHeight === '0px') {
                element.style.height = '250px';
                toggleIcon.style.transform = 'rotate(360deg)';
            }
            else {
                element.style.height = '0px';
                toggleIcon.style.transform = 'rotate(180deg)';
            }
        }
    }

    useEffect(() => {
        if(boardId !== 0 && typeof data !== 'undefined') {
            const obj = document.getElementById(boardId);
            animateNumber(obj, 0, data, 1500);
        }
    }, [data])

    return (
        <div className='board'>
            <div className='title'>
                {title}
                {
                    chart ?
                    <div onClick={toggleChart}><i id='toggleIcon' className='fa fa-arrow-circle-o-up'></i></div>
                    :null
                }
            </div>
            <div className='body'>
                {
                    chart ? 
                    <div className='chart'>
                    {/* TODO... */}
                    </div>
                    :null
                }
                <div className='data'>
                    <p id={boardId} className='boardData'>{typeof data !== 'undefined' ? data : 0}</p>
                    <sub>{subText}</sub>
                </div>
            </div>
        </div>
    );
}

export default BoardInfo;
