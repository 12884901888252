import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form, Field } from 'react-final-form';
import LoadingButon from '../../../../../Components/LoadingButton';
import ToggleField from '../../../../../Components/Form/Toggle';
import { useUserContext } from '../../../../../Contexts/userContext';
import { usePopupContext } from '../../../../../Contexts/popupContext';
import { request } from '../../../../../Services/networkService';
import { addLog } from '../../../../../Util/common';

const AboutUs = () => {
    const [dataText, setDataText] = useState('');
    const { handleError, popupMessageOpen } = usePopupContext();
    const { user } = useUserContext();
    const modules = {
        toolbar: [
          [{ 'font': [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    
          [{ 'align': [] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ],
    }

    const onSubmit = ({confirm}) => {
        let body = {};
        let logData = {
            type: 'Documents',
            title: 'Section: About Us',
            message: `There are new changes on the 'About Us' page updated by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
        };

        if(user && confirm && dataText) {

            body = {
                updatedBy: {
                    Id: user._id,
                    First_Name: user.First_Name,
                    Last_Name: user.Last_Name,
                    Email: user.Email
                },
                text: dataText
            }

            request('admin.updateAboutUs', body)
            .then(result => {
                addLog(logData, user);
                popupMessageOpen('Message', 'Data updated successfully');
            })
            .catch((e) => handleError(e))
        }
    }

    useEffect(() => {
        request('public.listAboutUs')
        .then(res => {
            if(Object.keys(res).length) {
                setDataText(res[0].text);
            }
        })
        .catch((e) => handleError(e));
    }, []);

    return (
        <div className="contentContainer central-meta">
            <div className="editing-info">
                <h5 className="f-title"><i className="ti-info-alt" /> About Us</h5>
                <Form 
                    onSubmit={onSubmit}
                    initialValues={{ ...dataText }}
                    render={({ handleSubmit, submitting, values, pristine }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <ReactQuill 
                                    name="aboutUs"
                                    theme="snow"
                                    placeholder="'About US' page which will be presented on Memkeepers"
                                    value={dataText}
                                    onChange={e=> setDataText(e)}
                                    modules={modules}
                                    className="documentsEditor"
                                />

                                <div className='onoff-options' style={{ marginBottom: "20px" }}>
                                <Field component={ToggleField}
                                    id="confirm"
                                    name="confirm"
                                    label="Data update confirmation"
                                    text='After the data is updated the previos one will be overwritten!'
                                    type="checkbox"
                                />
                                </div>
                                <div className="submit-btns text-center">
                                    <LoadingButon iconCls="fa fa-save" loading={submitting} disabled={pristine} title="Update"/>
                                </div>
                            </form>
                        )
                    }}
                />
            </div>
        </div>
    );
}

export default AboutUs;
