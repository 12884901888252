import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form, Field } from 'react-final-form';
import LoadingButon from '../../../../../Components/LoadingButton';
import ToggleField from '../../../../../Components/Form/Toggle';
import { useUserContext } from '../../../../../Contexts/userContext';
import { usePopupContext } from '../../../../../Contexts/popupContext';
import { request } from '../../../../../Services/networkService';
import { addLog, getImageUrl } from '../../../../../Util/common';
import TextField from '../../../../../Components/Form/TextField';
import TextArea from '../../../../../Components/Form/TextArea';
import FileField from '../../../../../Components/Form/FileField';

const PageContent = ({ pageId, pageTitle }) => {
    const [dataText, setDataText] = useState();
    const [meta, setMeta] = useState({});
    const [URL, setURL] = useState();
    const [uploads, setUploads] = useState([]);
    const { handleError, popupMessageOpen } = usePopupContext();
    const { user } = useUserContext();
    const urlPrefix = window.location.protocol + '//' + window.location.hostname + '/blog/';
    const modules = {
        toolbar: [
          [{ 'font': [] }],
        //   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    
          [{ 'align': [] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['link'],
          ['clean']
        ],
    }

    const listPage = () => {
        request('public.listBlog', { id: pageId })
        .then(result => {
            setDataText(result.text);
            setMeta(result.meta);
            setURL(result.URL);
            setUploads(result.uploads)
        })
        .catch((e) => handleError(e))
    }

    const fileData = (data) => {
        let formData = new FormData();
        formData.append('text', dataText);
        formData.append('id', pageId);
        formData.append('meta', JSON.stringify(data.meta));
        formData.append('URL', data.URL);
        data.file && Object.keys(data.file).length && data.file.forEach(file => 
          formData.append('file', file)  
        )
    
        return formData;
    }

    const onSubmit = (data) => {
        let logData = {
            type: 'Blog',
            title: 'Section: Blog',
            message: `There are new changes on the '${pageTitle}' page updated by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
        };

        if(user && data.confirm && dataText) {
            request('admin.updateBlog', fileData(data))
            .then(result => {
                addLog(logData, user);
                popupMessageOpen('Message', 'Data updated successfully');
            })
            .catch((e) => handleError(e))
        }
    }

    useEffect(() => {
        listPage();
    }, [pageId]);

    return (
        <div className="contentContainer central-meta">
            <div className="editing-info">
                <Form 
                    onSubmit={onSubmit}
                    initialValues={{ ...dataText, meta, URL, uploads }}
                    render={({ handleSubmit, submitting, values, pristine }) => {
                        return (
                            <form onSubmit={handleSubmit} className="features">
                                <h5 className="f-title"><i className="ti-file" /> {pageTitle} - Metadata & Page Settings</h5>
                                <div className="form-row page-settings">
                                    <Field component={TextField}
                                        name="URL"
                                        type="text"
                                        placeholder="Page URL"
                                        prefixText={urlPrefix}
                                    />
                                    <Field component={TextField}
                                        name="meta.keywords"
                                        type="text"
                                        placeholder="Keywords"
                                    />
                                </div>
                                <div className='form-row page-settings'>
                                <Field component={TextArea}
                                    name="meta.description"
                                    type="text"
                                    placeholder="Meta Description | Max 160 Characters"
                                    prefixText={urlPrefix}
                                    maxLength="160"
                                    rows={1}
                                />
                                </div>
                                <h5 className="f-title"><i className="ti-file" /> Thumbnail</h5>
                                <div className='form-row page-settings'>
                                    <div className="form-column">
                                        <div className='fbPost upload'>
                                            <Field component={FileField}
                                            name="file"
                                            type="file"
                                            accept="image/*,.pdf"
                                            label='Recommended dimensions: 800x800. Lower resolution images can be uploaded but may affect quality.'
                                            max={1}
                                            filespreview='true'
                                            placeholder="Select Thumbnail"
                                            />
                                        </div>
                                    </div>
                                    {
                                        uploads.length ?
                                        <div className='form-column'>
                                            <div className='fbPost upload'>
                                                <div className='filesPreview'>
                                                    <div className='fbUploadContainer'>
                                                        <div className='form-file-upload fbUpload'>
                                                            <span>Current Thumbnail</span>
                                                        </div>
                                                        <div className='img'>
                                                            <img loading='eager' src={getImageUrl(uploads[0].url)}></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :null
                                    }
                                </div>

                                <h5 className="f-title"><i className="ti-file" /> Page Content</h5>

                                <ReactQuill 
                                    name="memorials"
                                    theme="snow"
                                    placeholder="'Memorials' page which will be presented on Memkeepers"
                                    value={dataText}
                                    onChange={e=> setDataText(e)}
                                    modules={modules}
                                    className="documentsEditor"
                                />

                                <div className='onoff-options' style={{ marginBottom: "20px" }}>
                                <Field component={ToggleField}
                                    id="confirm"
                                    name="confirm"
                                    label="Data update confirmation"
                                    text='After the data is updated the previos one will be overwritten!'
                                    type="checkbox"
                                />
                                </div>
                                <div className="submit-btns text-center">
                                    <LoadingButon iconCls="fa fa-save" loading={submitting} disabled={pristine} title="Update"/>
                                </div>
                            </form>
                        )
                    }}
                />
            </div>
        </div>
    );
}

export default PageContent;
