import React from 'react';
import Layout from '../../Components/Layout';

import Users from './users';
import Memobjects from './memobjects';
import Exceptions from './errors';
import Groups from './groups';
import OrphanedHonorees from './orphanedHonorees';
import OrphanedPages from './orphanedPages';
import Honorees from './honorees';
import Products from './products';
import Tokens from './tokens';
import Pages from './pages';
import Ads from './ads/ads';

import { useTranslation } from "react-i18next";
import Dashboard from './dashboard';
import Promotions from './promotions/promotions';
import Policies from './documents';
import FB_Posts from './fbPosts/fbPosts';
import Memorials from './memorials';
import Blog from './blog';
import SEO_Posts from './seo_posts';

export default function AdminPanel() {
  const {t} = useTranslation('common');

  const menuConfig = [
    {
      title: 'Dashboard',
      contents: Dashboard,
      iconCls: 'ti-dashboard',
      path: '',
      params: {}
    },{
      title: 'Policies',
      contents: Policies,
      iconCls: 'ti-layers-alt',
      path: 'policies',
      params: {}
    },{
      title: 'Home Page',
      contents: Memorials,
      iconCls: 'ti-pencil-alt',
      path: 'memorials',
      params: {}
    },{
      title: 'Blog',
      contents: Blog,
      iconCls: 'ti-desktop',
      path: 'blog',
      params: {}
    },{
      title: 'SEO Posts',
      contents: SEO_Posts,
      iconCls: 'ti-desktop',
      path: 'seo_posts',
      params: {}
    },{
      title: 'Products',
      contents: Products,
      iconCls: 'ti-package',
      path: 'products',
      params: {}
    },{
      title: 'FB Posts',
      contents: FB_Posts,
      iconCls: 'ti-bookmark-alt',
      path: 'fbPosts',
      params: {}
    },{
      title: 'Promotions',
      contents: Promotions,
      iconCls: 'ti-ticket',
      path: 'promotions',
      params: {}
    },{
      title: t('admin.users'),
      contents: Users,
      iconCls: 'ti-id-badge',
      path: 'users',
      params: {}
    },{
      title: t('header.honorees'),
      contents: Honorees,
      iconCls: 'ti-agenda',
      path: 'honorees',
      params: {}
    },{
      title: t('admin.memobjects'),
      contents: Memobjects,
      iconCls: 'ti-files',
      path: 'memobjects',
      params: {}
    },{
      title: 'Pages',
      contents: Pages,
      iconCls: 'ti-notepad',
      path: 'pages',
      params: {}
    },{
      title: 'Tokens',
      contents: Tokens,
      iconCls: 'ti-gift',
      path: 'tokens',
      params: {}
    },{
      title: t('admin.groups'),
      contents: Groups,
      iconCls: 'ti-world',
      path: 'groups',
      params: {}
    },{
      title: t('admin.orphanedHonorees'),
      contents: OrphanedHonorees,
      iconCls: 'ti-save',
      path: 'orphaned-honorees',
      params: {}
    },{
      title: t('admin.orphanedPages'),
      contents: OrphanedPages,
      iconCls: 'ti-save-alt',
      path: 'orphaned-pages',
      params: {}
    },{
      title: 'Ads',
      contents: Ads,
      iconCls: 'ti-announcement',
      path: 'ads',
      params: {}
    },{
      title: 'Error Messages',
      contents: Exceptions,
      iconCls: 'ti-archive',
      path: 'exceptions',
      params: {}
    },
  ];

  return(
    <Layout config={menuConfig} title={t('admin.adminPanelSidebar')}/>
  );
};
