import React, { useState } from 'react';
import { getImageUrl } from '../../Util/common';

const BoardImages = ({ data, title, subText, images = [] }) => {
    const [menuHidden, setMenuHidden] = useState(true);

    function toggleChart() {
        if(images.length) {
            const element = document.getElementsByClassName('imageScroll')[0];
            const elementHeight = element.style.height ? element.style.height : '0px';
            const toggleIcon = document.getElementById('toggleIcon');

            if(elementHeight === '0px') {
                setMenuHidden(false);
                element.style.height = '250px';
                toggleIcon.style.transform = 'rotate(180deg)';
            }
            else {
                setMenuHidden(true);
                element.style.height = '0px';
                toggleIcon.style.transform = 'rotate(360deg)';
            }
        }
    }

    return (
        <div className='board large'>
            <div className='title'>
                {title}
                {
                    images.length ?
                    <div onClick={toggleChart}><i id='toggleIcon' className='fa fa-arrow-circle-o-down'></i></div>
                    :null
                }
            </div>
            <div className='body'>
                {
                    images.length ? 
                    <div className='imageScroll'>
                    {
                        images.map((img, i) => {
                            const imageUrl = getImageUrl(img.moURL);
                            return (
                                <div key={i} className='dataElement'>
                                <img src={imageUrl} alt='Memento'/>
                                <sub>Used&nbsp;<b>{img.count}</b>&nbsp;times</sub>
                                </div>
                            )
                        })
                    }
                    </div>
                    :null
                }
                {
                    menuHidden && images.length ?
                    <p className='hiddenText'>Click the top right button to view more content</p>
                    :null
                }
                <div className='data'>
                    <p className='boardData'>{data ? data : menuHidden ? null : String.fromCharCode(160)}</p>
                    <sub>{subText}</sub>
                </div>
            </div>
        </div>
    );
}

export default BoardImages;
