import React, { useState } from 'react';
import { usePopupContext } from '../../Contexts/popupContext';
import { useUserContext } from '../../Contexts/userContext';
import { addLog, getImageUrl } from '../../Util/common';
import { Field } from 'react-final-form';
import { required } from '../../Util/form';
import Select from '../../Components/Form/Select';
import { request } from '../../Services/networkService';

const BoardMostUsers = ({ boardId, data, title, subText, logins = false, posts = false, mementos = false, ads = false, users = [] }) => {
    const { handleError, popupFormOpen, popupConfirmOpen } = usePopupContext();
    const { products, user } = useUserContext();
    const [menuHidden, setMenuHidden] = useState(true);
    const k_ = String.fromCharCode(160);    

    const options = products ? products.filter(p => p.name !== 'Memento') : [];

    const tokenFormPopup = () => (
        <div className="form-row">
    
          <Field component={Select}
           name="type"
           label="Token Type"
           validate={required}
           required
           options={options}
          />
    
        </div>
    );

    function toggleChart() {
        if(users.length) {
            const element = document.getElementById(`imageScroll-${boardId}`);
            const elementHeight = element.style.height ? element.style.height : '0px';
            const toggleIcon = document.getElementById(`toggleIcon-${boardId}`);

            if(elementHeight === '0px') {
                setMenuHidden(false);
                element.style.height = '250px';
                toggleIcon.style.transform = 'rotate(180deg)';
            }
            else {
                setMenuHidden(true);
                element.style.height = '0px';
                toggleIcon.style.transform = 'rotate(360deg)';
            }
        }
    }

    function sendToken(user) {
        popupFormOpen('Sent token', tokenFormPopup(false))
        .then((result) => {
            const tknData = {...result, issuedFromName: 'MEMKEEPERS - Administration', owner: user.id, ownerName: `${user.names} - ${user.email}`, ownerEmail: user.email};
            return request(`admin.tokens.create`, tknData)
            .then(data => saveLog(data))
            .catch(handleError);
        })
    }

    const saveLog = (data) => {
        let logData = {
          type: 'Tokens',
          title: `Token: ${data.token}`,
          message: `Token '${data.token}' has been issued by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
        };

        addLog(logData, user);
    }

    return (
        <div className='board large'>
            <div className='title'>
                {title}
                {
                    users.length ?
                    <div onClick={toggleChart}><i id={`toggleIcon-${boardId}`} className='fa fa-arrow-circle-o-down'></i></div>
                    :null
                }
            </div>
            <div className='body'>
                {
                    users.length ? 
                    <div className='imageScroll users' id={`imageScroll-${boardId}`}>
                    {
                        users.map((user, i) => {
                            const imageUrl = getImageUrl(user.uData.profileImg);
                            return (
                                <div key={i} className='dataElement'>
                                {
                                    mementos || ads ?
                                    <div className='imgContainer' onClick={() => sendToken(user.uData)}>
                                        <div className='imgCover'><p>Sent a token</p></div>
                                        <img key={i} src={imageUrl} alt='User'/>
                                    </div>
                                    :
                                    <img key={i} src={imageUrl} alt='User'/>
                                }
                                {
                                    logins ?
                                    <>
                                        <sub>
                                        <b>{user.uData.names}</b> has logged in <b>{user.count}</b>{k_}times
                                        </sub>
                                        <br/>
                                        <sub>
                                        <b>{user.uData.email}</b>
                                        </sub>
                                    </>
                                    : posts ?
                                    <>
                                        <sub><b>{user.uData.names}</b> has posted <b>{user.count}</b>{k_}times</sub>
                                        <br/>
                                        <sub>
                                        <b>{user.uData.email}</b>
                                        </sub>
                                    </>
                                    : mementos ?
                                    <>
                                        <sub><b>{user.uData.names}</b> has posted <b>{user.count}</b>{k_}mementos</sub>
                                        <br/>
                                        <sub>
                                        <b>{user.uData.email}</b>
                                        </sub>
                                    </>
                                    : ads ?
                                    <>
                                        <sub><b>{user.uData.names}</b> has posted <b>{user.count}</b>{k_}ads</sub>
                                        <br/>
                                        <sub>
                                        <b>{user.uData.email}</b>
                                        </sub>
                                    </>
                                    :<sub>{user.uData.names} - <b>{user.uData.email}</b></sub>
                                }
                                </div>
                            )
                        })
                    }
                    </div>
                    :null
                }
                {
                    menuHidden && users.length ?
                    <p className='hiddenText'>Click the top right button to view more content</p>
                    :null
                }
                <div className='data'>
                    <p className='boardData'>{data ? data : menuHidden ? null : users.length ? k_ : 0}</p>
                    <sub>{subText}</sub>
                </div>
            </div>
        </div>
    );
}

export default BoardMostUsers;
