import React from 'react';
import AboutUs from './pages/aboutUs';
import ContactUs from './pages/contactUs';
import HowItWorks from './pages/howItWorks';
import Policies from './pages/policies';

const Content = ({pageId}) => {

    const getPage = (id) => {
        switch (id) {
            case 0:
                return <AboutUs />;
                break;
            case 1:
                return <ContactUs />;
                break;
            case 2:
                return <Policies />;
                break;
            case 3:
                return <HowItWorks />;
                break;
            default:
                break;
        }
    }

    return (
        <div className='contentContainer'>
            {getPage(pageId)}
        </div>
    );
}

export default Content;
