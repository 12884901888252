import React from 'react';
import Layout from '../../Components/Layout';
import { useTranslation } from "react-i18next";
import { useUserContext } from '../../Contexts/userContext';

import All from './all';
import SavedCards from './savedCards';
import Tokens from '../Profile/tokens';
import ReceivedTokens from '../Profile/receivedTokens';

export default function Requests() {
  const { user, setUser } = useUserContext();
  const {t} = useTranslation('common');

  const pageParams = {
    user: user,
    readOnly: false,
    update: setUser
  };

  const menuConfig = [
    {
      title: t('purchases.allPurch'),
      contents: All,
      iconCls: 'ti-agenda',
      path: '',
      params: {}
    },
    {
      title: t('purchases.savedCards'),
      contents: SavedCards,
      iconCls: 'fa fa-credit-card',
      path: 'savedCards',
      params: {}
    },
    {
      title: 'Sended Tokens',
      contents: Tokens,
      iconCls: 'fa fa-diamond',
      path: 'tokens',
      params: pageParams
    },
    {
      title: 'Received Tokens',
      contents: ReceivedTokens,
      iconCls: 'fa fa-diamond',
      path: 'receivedTokens',
      params: pageParams
    },
  ];

  return(
    <Layout config={menuConfig} title={t('header.finances')} />
  );
};
