import React from 'react';
import PageContent from './pages/pageContent';

const Content = ({pageId, pageTitle}) => {

    return (
        <div className='contentContainer'>
            {
                pageId !== 0 && <PageContent pageId={pageId} pageTitle={pageTitle} />
            }
        </div>
    );
}

export default Content;
