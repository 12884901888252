import React, { Fragment, useEffect, useRef } from 'react';

export default function SelectField ({ input, meta, label, required, children, noEmptyOption=false, ...others }) {
  const style = ((meta.error || meta.submitError) && meta.touched && !others.readOnly) ? { 'color': 'red' } : {};
  const optionTitleProp = others.optiontitleprop || 'name';
  const optionIdProp = others.optionidprop || 'id';

  const ref = useRef(null);

  const match = others.readOnly && others.options && others.options.find(o => o[optionIdProp].toString() === input.value.toString());
  const text = match ? match[optionTitleProp] : '';

  const renderDropdown = (
    <select 
      {...input} 
      {...others}
      ref={ref} 
      onChange={(...args) => {
        input.onChange(...args);
        others.onChange && others.onChange(...args);
      }}>
      {
        others.options &&
        <Fragment>
          {!noEmptyOption && <option />}
          {others.options.map(opt => <option key={opt[optionIdProp]} value={opt[optionIdProp]}>{opt[optionTitleProp]}</option>)}
        </Fragment>
      }
      {children}
    </select>
  );

  useEffect(()=>{
    ref.current && ref.current.setCustomValidity((meta.error && meta.touched) ? meta.error : '')
  });

  return (
    <div className="form-group" style={{ opacity: others.disabled ? 0.5 : 1}}>  
      <label htmlFor={others.id} style={style}>{label} {required && !others.readOnly && <span className="is-required">*</span>}</label>
      
      {
        others.readOnly ? 
          <div>{text}</div>
        :
          renderDropdown
      }

      <i className="mtrl-select" />
    </div>
  );
}