import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Content from './content';
import Header from './header';
import { useParams } from 'react-router-dom';
import { request } from '../../../Services/networkService';
import { usePopupContext } from '../../../Contexts/popupContext';
import { formatDate } from '../../../Util/common';

const Logs = () => {
    const { handleError } = usePopupContext();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [formattedData, setFormattedData] = useState([]);

    useEffect(() => {
        request('admin.logs.list', {id: id})
        .then(result => {
            setData(result);
            setFormattedData(result);
        })
        .catch(err => handleError(err))
    }, [])

    const addFilter = (receivedData, range = false) => {
        const now = new Date();
        const dataCopy = data;
        let newData = [];
        if(receivedData) {
            if(typeof formattedData !== 'undefined') {
                if(range) {
                    newData = dataCopy.filter(item => formatDate(item.createdAt) >= formatDate(receivedData) && formatDate(item.createdAt) <= formatDate(now));
                } else {
                    newData = dataCopy.filter(item => formatDate(item.createdAt) === formatDate(receivedData));
                }
                setFormattedData(newData);
            }
        } else {
            setFormattedData(dataCopy);
        }
    }

    return (
        <div className='logsCnt'>
            <Link to={'/admin/users'}>Back to Admin Panel</Link>
            <Header addFilter={addFilter}/>
            <Content data={formattedData}/>
        </div>
    );
}

export default Logs;
