import React, { useRef, useEffect } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { statusColumn } from '../../Components/ReactTable/StatusColumn';
import { userRole, formatDateTime, addLog } from '../../Util/common';
import { usePopupContext } from '../../Contexts/popupContext';
import { request, saveTrackingData } from '../../Services/networkService';
import Select from '../../Components/Form/Select';
import { Field } from 'react-final-form';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import LoadingButton from '../../Components/LoadingButton';
import UserStatistics from './users/statistics';
import { useUserContext } from '../../Contexts/userContext';

export default function Users(){
  const {t} = useTranslation('common');
  const { handleError, popupFormOpen, popupConfirmOpen } = usePopupContext();
  const { user } = useUserContext();
  const tableRef = useRef();
  const history = useHistory();

  const columns = [statusColumn(), {
    Header: t('admin.email'),
    accessor: 'Email',
    width: 300
  },{
    Header: t('admin.firstName'),
    accessor: 'First_Name'
  },{
    Header: t('admin.lastName'),
    accessor: 'Last_Name'
  },{
    Header: "Created At",
    accessor: 'createdAt',
    Cell: ({value}) => value ? formatDateTime(value) : ''
  },{
    Header: t('admin.role'),
    accessor: 'AccessLevel',
    Cell: ({value}) => userRole(value),
    Filter: () => {}
  }];

  const accessLevelPopup = (
    <div className="form-row">
      <Field
        component={Select}
        name="AccessLevel"
        label={t('admin.setUserRole')}
        options={[
          {id: 0, name: t('admin.user')},
          {id: 3, name: t('admin.admin')}
        ]}
      />
    </div>
  );

  const actions = [{
    iconcls: 'fa fa-universal-access',
    onClick: (row, {viewIndex}) => popupFormOpen(t('admin.userAccessLevel'), accessLevelPopup, {initialValues: row})
      .then(data =>
        request('admin.users.update', data)
          .then(res => tableRef.current.updateSingleRow(viewIndex, res))
          .then(() => saveLog(row))
      ),
    title: t('admin.setAccessLevel'),
  },{ 
    iconcls: 'ti-lock',
    onClick: row => popupConfirmOpen('Confirm', `Are you sure you want to restrict the account of ${row.First_Name + ' ' + row.Last_Name}?`)
      .then(() => {
        request('admin.users.update', {id: row._id, Blocked: true})
        .then(tableRef.current.refresh)
      }),
    title: "Block user",
    condition: row => row.Blocked === false
  },{
    iconcls: 'ti-unlock',
    onClick: (row, {viewIndex}) => request('admin.users.update', {id: row._id, Blocked: false})
      .then(tableRef.current.refresh),
    title: "Unblock user",
    condition: row => row.Blocked === true
  },{
    iconcls: 'ti-trash',
    onClick: row => popupConfirmOpen('Confirm', `Are you sure you want to delete the account of ${row.First_Name + ' ' + row.Last_Name}?`)
      .then(() => {
        request('admin.users.delete', {id: row._id})
          .then(saveLog(row, true))
          .then(tableRef.current.refresh)
      }),
    title: "Delete user"
  }];

  const doExport = () => request('admin.users.export')
  .then(data => {
    const blob = new Blob([data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = "users.csv";
    link.click();
    saveLog({}, true);
  })
  .catch(handleError);

  const saveLog = (data, exported = false) => {
    let logData = {
      type: 'Users',
      title: exported ? 'Exported Users' : `User: ${data.First_Name} ${data.Last_Name}`,
      message: exported ? 
      `${user.First_Name} ${user.Last_Name} - ${user.Email} has exported users.` 
      : 
      `The access of user '${data.First_Name} ${data.Last_Name} - ${data.Email}' has been updated by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
    };

    addLog(logData, user);
  }

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/users')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-id-badge" /> {t('admin.userManagement')}</h5>
        <UserStatistics />
        <br/>
        <div className="submit-btns text-center">
          <LoadingButton onClick={doExport} title="Export users" />
        </div>

        <h3>Administrators List</h3>
        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.users.list'}
          apiParam={true}
          onError={handleError}
          onRowClick={data => data.AccessLevel === 3 ? history.push({pathname: '/logs/' + data._id}) : null}
        />
        <br/>
        <h3>Users List</h3>
        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.users.list'}
          onError={handleError}
          onRowClick={data => data.AccessLevel === 3 ? history.push({pathname: '/logs/' + data._id}) : null}
        />

      </div>
    </div>
  );
}
