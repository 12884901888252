import React, { useRef, useEffect } from 'react';
import ReactTable from '../../Components/ReactTablePagination';
import { usePopupContext } from '../../Contexts/popupContext';
import { request, saveTrackingData } from '../../Services/networkService';
import FileField from '../../Components/Form/FileField';
import Select from '../../Components/Form/Select';
import CheckBox from '../../Components/Form/CheckBox';
import { Form, Field } from 'react-final-form';
import { required } from '../../Util/form';
import { memobjectCategories } from '../../Util/constants';
import { useGalleryContext } from '../../Contexts/galleryContext';
import { generateTextDropdown } from '../../Components/ReactTable/Filters';
import { addLog, getImageUrl } from '../../Util/common';
import { useTranslation } from "react-i18next";
import MemobjectStatistics from './memobjects/statistics';
import { useUserContext } from '../../Contexts/userContext';

export default function Memobjects(){
  const {t} = useTranslation('common');
  const { handleError, popupFormOpen, popupConfirmOpen } = usePopupContext();
  const tableRef = useRef();
  const { galleryOpen } = useGalleryContext();
  const { user } = useUserContext();
  const checkedMomentos = [];

  const isAlreadyChecked = (id) => {
    return checkedMomentos.includes(id);
  }

  const onDeselectMomento = (id, clearList = false) => {
    if(clearList) {
      checkedMomentos.length = 0;
    } else {
      const index = checkedMomentos.indexOf(id);
      if(index > -1) checkedMomentos.splice(index, 1);
    }
  }

  const onSelectMomento = (event) => {
    const id = event.currentTarget.value;
    if(!isAlreadyChecked(id)) checkedMomentos.push(id);
    else onDeselectMomento(id);
    tableRef.current.refresh();
  }

  const onSubmitDelete = () => {
    const isOneElement = checkedMomentos.length === 1 ? true: false;
    let checkAllElement = document.getElementById('checkAll');
    popupConfirmOpen('Confirm', `Are you sure you want to delete ${isOneElement ? 'this memobject' : 'these memobjects'}?`)
    .then(() => {
      if(isOneElement) {
        request('admin.memobjects.delete', {id: checkedMomentos[0], multiple: false})
        .then(() => {
          saveLog({id: checkedMomentos[0]}, true);
          onDeselectMomento(checkedMomentos[0]);
          tableRef.current.refresh();
          checkAllElement.checked = false;
        });
      }
      else {
        request('admin.memobjects.delete', {id: encodeURIComponent(JSON.stringify(checkedMomentos)), multiple: true})
        .then(() => {
          saveLog({}, true, false, true);
          onDeselectMomento('', true);
          tableRef.current.refresh();
          checkAllElement.checked = false;
        });
      }
    })
  }

  const onSubmitAll = (event) => {
    event.preventDefault();
    const checkedBox = event.currentTarget.checked;
    const tableData = tableRef.current.getRows();
    const dataLength = tableData.length <= 30 ? tableData.length : 30;
    const dataLengthFull = tableData.length;

    if(checkedBox) {
      for(let i=0; i<dataLength; i++) {
        if(!isAlreadyChecked(tableData[i].id)) checkedMomentos.push(tableData[i].id);
        tableRef.current.refresh();
      }
    } else {
      for(let i=0; i<dataLengthFull; i++) {
        onDeselectMomento(tableData[i].id);
        tableRef.current.refresh();
      }
    }
  }

  const columns = [{
    Header: ({value}) => {
      return (
        <div style={{ transform: 'translate(25%, -25%' }}>
          <Form
            onSubmit={onSubmitAll}
            initialValues={{ value }}
            render={({ handleSubmit, submitting, values, pristine }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field component={CheckBox}
                    id="checkAll"
                    name="checkAll"
                    type="checkbox"
                    value='CheckAll'
                    onClick={(e) => onSubmitAll(e)}
                    checked={checkedMomentos.length}
                  />
                </form>
              )
            }}
          />
      </div>
      )
    },
    accessor: '_id',
    width: 40,
    resizable: false,
    sortable: false,
    Filter: () => {},
    Cell: ({value}) => {
      return (
        <div style={{ transform: 'translate(25%, -25%' }}>
          <Form
            onSubmit={onSelectMomento}
            initialValues={{ value }}
            render={({ handleSubmit, submitting, values, pristine }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field component={CheckBox}
                    name="id"
                    type="checkbox"
                    value={value}
                    onClick={(e) => onSelectMomento(e)}
                    checked={checkedMomentos.includes(value)}
                  />
                </form>
              )
            }}
          />
        </div>
      )
    }
  },
  {
    Header: ({value}) => {
      return (
        <div style={{ transform: 'translate(25%, -20%' }}>
          {
            checkedMomentos.length >= 1 ?
            <Form
              onSubmit={onSubmitDelete}
              initialValues={{ value }}
              render={({ handleSubmit, submitting, values, pristine }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className='icon grid-icon fa fa-trash-o' style={{ cursor: 'pointer' }} onClick={onSubmitDelete}></div>
                  </form>
                )
              }}
            />
            :null
          }
      </div>
      )
    },
    width: 60,
    accessor: 'Url',
    sortable: false,
    resizable: false,
    Cell: ({value}) => {
      const imageUrl = getImageUrl(value);
      return(
      <div onClick={() => galleryOpen(imageUrl)} className="grid-row-img" style={{backgroundImage: `url("${imageUrl}")`}}></div>
      )
    }
  },
  // {
  //   Header: t('admin.type'),
  //   accessor: 'Premium',
  //   Filter: generateTextDropdown([{name: 'Free', id: '$nottrue'}, {name: 'Premium', id: true}], true),
  //   Cell: ({value}) => value ? 'Premium' : 'Free'
  // },
  {
    Header: t('admin.category'),
    accessor: 'Category',
    sortable: false,
    resizable: false,
    Filter: generateTextDropdown(memobjectCategories, true),
    Cell: ({value}) => {
      const res = memobjectCategories.find(o => o.id === value);
      return res ? res.name : '';
    }
  }];

  const memobjectFormPopup = editMode => (
    <div className="form-row">
      { !editMode &&
        <Field component={FileField}
         name="file"
         type="file"
         label={t('admin.selectFile')}
         validate={required}
         required
         accept="image/*"
         multiselect={true}
        />
      }
      <Field component={Select}
       name="Category"
       label={t('admin.category')}
       validate={required}
       required
       options={memobjectCategories}
      />
    </div>
  );

  const save = o => {
    const editMode = o._id;
    let data = new FormData();
    if(o.file.length > 1) {
      for(let i=0; i<o.file.length; i++) {
        data.append('Name', o.file[i].name);
        data.append('Category', o.Category);
        data.append('Premium', o.Premium);
        !editMode && data.append('file', o.file[i]);
      }
    } else {
      data.append('Name', o.Name);
      data.append('Category', o.Category);
      data.append('Premium', o.Premium);
      !editMode && data.append('file', o.file[0]);
      editMode && data.append('id', o._id);
    }

    return request(`admin.memobjects.${editMode ? 'update' : 'create'}`, data)
      .catch(handleError);
  };

  const actions = [{
    iconcls: 'fa fa-pencil-square-o',
    onClick: (row, {viewIndex}) => popupFormOpen('Edit memento', memobjectFormPopup(true), {initialValues: row})
      .then(save)
      .then(res => tableRef.current.updateSingleRow(viewIndex, res))
      .finally(() => saveLog(row)),
    title: t('admin.editMemobject')
  }];

  const saveLog = (data, deleted = false, created = false, multiple = false) => {
    let logData = {
      type: 'Mementos',
      title: multiple ? 'Multiple Mementos' : `Memento: ${data.id}`,
      message: multiple ?
      `Multiple mementos has been ${deleted ? 'deleted' : created ? 'created' : 'updated'} by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`
      :
      `Memento '${data.id}' has been ${deleted ? 'deleted' : created ? 'created' : 'updated'} by: ${user.First_Name} ${user.Last_Name} - ${user.Email}`,
    };

    addLog(logData, user);
  }

  useEffect(() => {
    if(user) {
      saveTrackingData(user, '/admin/memobjects')
    }
  }, []);

  return (
    <div className="central-meta">
      <div className="editing-info">
        <h5 className="f-title"><i className="ti-files" /> {t('admin.memobjects')}</h5>
        <MemobjectStatistics />
        <br/>
        <ReactTable
          ref={tableRef}
          columns={columns}
          actions={actions}
          api={'admin.memobjects.list'}
          onError={handleError}
          openAddPopup={() => popupFormOpen(t('admin.uploadMemobject'), memobjectFormPopup(false))
            .then(save)
            .then((data) => saveLog(data, false, true))
            .then(tableRef.current.refresh)
          } 
          // defaultSorted={[{
          //   id: 'Category',
          //   desc: true
          // }]}
        />

      </div>
    </div>
  );
}
